<div class="container">
  <header class="pageHeader">
    <h3>
      <i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.EinvoiceFeatures' | translate}}</h3>
  </header>

  <div [@fadeInOut]>
    <div class="row">
      <div class="col-md-11 offset-md-1">
        <p>
          2018. július 1-től a számlázó programoknak képesnek kell lenniük arra, hogy a NAV által megadott formátumban a számla kiállítását
          követően automatikusan adatot szolgáltassanak Internetes kapcsolaton keresztül az adóhatóság számára.
        </p>
        <p>
          Komplex ügyviteli rendszerünk számlázó modulja várhatóan 2019-ben készül el, de a Novaplan-SzámlaW számlázó alkalmazásunk felhasználói
          részére rendszerünk biztosítja az előírt formában történő adattovábbítást.
        </p>
        <h5>
          <b>Az adattovábbítás folyamata</b>
        </h5>
        <ul style="list-style-type:decimal">
          <li>
            A Novaplan-SzámlaW program előállítja a számla kiállítása után a kötelező adattartalommal a NAV részére megküldendő XML állományt.
          </li>
          <li>
            A számlázó program munkaállomásán vagy a helyi szerveren futó adattovábbító alkalmazásunk folyamatosan figyeli, hogy készült-e
            továbbítandó állomány.
            Amennyiben van ilyen, akkor az állományt elküldi a Novaplan-KUR rendszerünknek.
          </li>
          <li>
            A novaplan-KUR rendszer továbbítja az adatokat a NAV-nak. A továbbított adatok státuszáról ezen a webes felületünkön tájékozódhatnak.
            Sikeres adattovábbítás esetén a NAV online számla felületen is megjelennek az adatok, illetve a NAV honlapján is lekérdezhető az
            adatküldés státusza.
          </li>
        </ul>
        <p>
          A folyamat részletes leírása megtalálható a <a href="https://drive.google.com/open?id=1r4DCjXXz0hSRWZPreQEFLDGrc_lNN9F3"
            target="_blank">felhasználói útmutatóban</a>.
          A rendszer használatához mind a NAV online-számla felületen, mind a novaplan-KUR rendszerbe regisztrálnia kell a cégét.
          Demo rendszerünk a NAV teszt rendszerébe van bekötve. A teszt rendszerbe beküldhető bármilyen teszt számla.
        </p>
        <h5>
          <b>Tennivalók az adattovábbítás előkészítéséhez</b>
        </h5>
        <ul style="list-style-type:decimal">
          <li>
            <a href="https://onlineszamla.nav.gov.hu/" target="_blank">Regisztráció a NAV online-számla felületen.</a>
          </li>
          <li>
            Az adattovábbításhoz szükséges "technikai felhasználó" létrehozása a NAV felületén.
          </li>
          <li>
            <a href="javascript:;" routerLink="/signup">Regisztráció</a> a novaplan-KUR rendszerben.
          </li>
          <li>
            Az Ön munkaállomásain frissíteni kell a Novaplan-SzámlaW programot. Július 1. után a korábbi verziójú programunk csak abban az esetben
            használhatóak, ha azokkal 100.000 Ft ÁFA tartalmat el nem érő számlákat állítanak ki. <b>Az adatszolgáltatást nem teljesítő számlázó
              programok használatát
              a NAV súlyos mulasztási bírsággal bünteti! A megfelelő program alkalmazása az Adózó felelőssége!</b>
          </li>
          <li>
            Az Ön munkaállomásain vagy szerverén telepíteni kell a novaplan-KUR NAVImportWatcher alkalmazást, amely az adattovábbítást végzi a
            számlázó program és
            az online rendszerünk között.
          </li>
        </ul>
        <p>
          A NAV honlapján a techikai információk mellett részletes leírások találhatóak a NAV által elvárt adattartalomról is.
          Programunk felhasználói útmutatójában igyekszünk segíteni abban, hogy a legfontosabb szabályokra felhívjuk az Önök figyelmét.
        </p>
        <p>
          Online rendszerünk használatáról és a regisztrációról a keretrendszer
          <a href="https://drive.google.com/open?id=1YME8RKejahlLI4dGONHRTch6rRuq5BEW" target="_blank">felhasználói útmutatójában</a> talál
          információkat.
        </p>
        <p>
          <b>
            Az új adatszolgáltatás egy komplex informatikai folyamat. Emellett a számlák előállításakor is nagy figyelmet kell fordítani
            a jogszabályi előírásokra, az adattartalom pontos betartására. A helyes adatszolgáltatás érdekében folyamatosan gondoskodni kell az
            informatikai feltételek fennállásáról
            és arról is, hogy a számlázást végző kollégák tisztában legyenek a számla kiállítás szabályaival, illetve a folyamat lépéseivel!
          </b>
        </p>
      </div>
    </div>
  </div>
</div>

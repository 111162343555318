<div>
  <div class="row control-box">
    <div class="col-lg-8">
      <div class="form-group search-box">
        <search-box (searchChange)="onSearchChanged($event)" placeholder="{{'circles.management.Search' | translate}}">>
        </search-box>
      </div>
    </div>
    <div class="col-lg-4 pr-lg-5">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li class="nav-item toolbaritem">
          <a href="javascript:;" (click)="newCircle()">
            <i class="fa fa-plus-circle"></i> {{'circles.management.NewCircle' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ngx-datatable class="material colored-header sm table table-striped table-hover" [loadingIndicator]="loadingIndicator" [rows]="rows"
    [rowHeight]="35" [columnMode]="'force'" [headerHeight]="35" [footerHeight]="35" [columns]="columns" [scrollbarV]="true"
    [sorts]="[{prop: 'name', dir: 'asc'}]">
  </ngx-datatable>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
    <a *ngIf="canManageCircles" class="btn btn-link btn-sm" href="javascript:;" (click)="editCircle(row)"><i class="fa fa-pencil-square-o"
        aria-hidden="true"></i> {{'circles.management.Edit' | translate}}</a>
    {{canManageCircles ? '|' : ''}}
    <a *ngIf="canManageCircles" class="btn btn-link btn-sm" href="javascript:;" (click)="deleteCircle(row)"><i class="fa fa-trash-o"
        aria-hidden="true"></i> {{'circles.management.Delete' | translate}}</a>
  </ng-template>

  <div class="modal fade" bsModal #editorModal="bs-modal" (onHidden)="onEditorModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title float-left"><i class="fa fa-shield"></i>
            {{editingCircleName ? ('circles.management.EditCircle' | translate:editingCircleName) : ('circles.management.NewCircle' | translate)}}
          </h4>
          <button type="button" class="close float-right" title="Close" (click)="editorModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <circle-editor #circleEditor></circle-editor>
        </div>
      </div>
    </div>
  </div>
</div>

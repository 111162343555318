<div>
  <form *ngIf="formResetToggle" class="form-horizontal" name="circleEditorForm" #f="ngForm" novalidate (ngSubmit)="save()">

    <div class="form-circle row has-feedback-icon">
      <label class="col-form-label col-lg-3" for="circleName-{{uniqueId}}">{{'circles.editor.Name' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && circleName.valid, 'is-invalid' : f.submitted && !circleName.valid}">
        <input type="text" attr.id="circleName-{{uniqueId}}" id="circleName" name="circleName" placeholder="A kör neve 2-200 karakterből áll."
          class="form-control" [(ngModel)]="circleEdit.name" #circleName="ngModel" required minlength="2" maxlength="200" />
        <span *ngIf="showValidationErrors && f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': circleName.valid, 'fa-times' : !circleName.valid}"></span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-circle row">
      <label class="col-form-label col-lg-3" for="circleDescription">{{'circles.editor.Description' | translate}}</label>
      <div class="col-lg-9">
        <input type="text" id="circleDescription" name="circleDescription" placeholder="Ide tetszőleges további leírást adhat meg."
          class="form-control" [(ngModel)]="circleEdit.description" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <span *ngIf="showValidationErrors && f.submitted && !circleName.valid" class="invalid-feedback circleErrorMessage">
          {{'circles.editor.CircleNameRequired' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 card card-body bg-light usersRow">
        <span>{{'circles.editor.UserList' | translate}}</span>
        <custom-dual-list attr.id="usersList-{{uniqueId}}" id="usersList" [source]="allUsers" [(destination)]="selectedUsers"
          [key]="dualListBoxUsersKeyName" [display]="dualListBoxUsersDisplayName" [format]="format" height="285px" [filter]="true"></custom-dual-list>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr class="edit-last-separator-hr" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 card card-body bg-light companiesRow">
        <span>{{'circles.editor.CompanyList' | translate}}</span>
        <custom-dual-list attr.id="companiesList-{{uniqueId}}" id="companiesList" [source]="allCompanies" [(destination)]="selectedCompanies"
          [key]="dualListBoxCompaniesKeyName" [display]="dualListBoxCompaniesDisplayName" [format]="format" height="285px" [filter]="true">
        </custom-dual-list>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr class="edit-last-separator-hr" />
      </div>
    </div>

    <div class="form-circle row">
      <div class="col-sm-12">
        <div ngPreserveWhitespaces class="float-right">
          <button type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i>
            {{'circles.editor.Cancel' | translate}}</button>
          <button type="submit" class="btn btn-primary" [disabled]="isSaving">
            <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i>
            {{isSaving ? ('circles.editor.Saving' | translate) : ('circles.editor.Save' | translate)}}
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </form>
</div>

import { Component, OnInit, TemplateRef, ViewChild, Input, AfterViewInit, Output, EventEmitter, SimpleChanges } from "@angular/core";

import { AlertService, MessageSeverity } from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { MasterDataService } from "../../services/master-data.service";
import { AuthService } from "../../services/auth.service";
import { Utilities } from "../../services/utilities";

import { NavOnline } from "../../models/nav-online.model";

@Component({
  selector: "nav-online-info",
  templateUrl: "./nav-online-info.component.html",
  styleUrls: ["./nav-online-info.component.scss"]
})

export class NavOnlineInfoComponent implements OnInit, AfterViewInit {
  public formResetToggle = true;
  public columns: any[] = [];
  public minDate: Date;
  public maxDate: Date;
  public datePickerConfig: string;

  public changesSavedCallback: (navOnline: NavOnline) => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  public isViewOnly: boolean;

  public isSaving = false;
  public uniqueId: string = Utilities.uniqueId();
  public navOnline: NavOnline = new NavOnline();
  private navOnlineInit: NavOnline = new NavOnline();
  private companyId: number;
  private dateFrom: Date;
  private dateTo: Date;

  @ViewChild("resendTemplate", { static: true })
  public resendTemplate: TemplateRef<any>;

  @ViewChild("dateTimeTemplate", { static: true })
  public dateTimeTemplate: TemplateRef<any>;

  @ViewChild("f", { static: false })
  private form;

  @Input()
  private navUserName;

  @Input()
  private taxNumber;

  @Input()
  private xmlSignKey;

  @Input()
  private exchangeKey;

  @Input()
  private password;

  @Input()
  private immediateEmailNotification;

  @Input()
  private notificationEmailAddress;

  @Input()
  private dailyEmailNotification;

  @Input()
  private dailyNotificationEmailAddress;

  constructor(private alertService: AlertService,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private masterDataService: MasterDataService) {
  }

  // public ngOnChanges(changes: SimpleChanges) {
  //   // changes.prop contains the old and the new value...
  //   for (const propName in changes) {
  //     const change = changes[propName];
  //     const curVal = JSON.stringify(change.currentValue);
  //     const prevVal = JSON.stringify(change.previousValue);
  //     console.log(curVal);
  //     console.log(prevVal);
  //   }
  // }

  public ngOnInit() {
    this.datePickerConfig = "{ rangeInputFormat: 'YYYY-MM-DD', showWeekNumbers: true }";
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 600);
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.dateFrom.setDate(this.minDate.getDate() - 31);
    this.dateTo.setDate(this.maxDate.getDate());

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      { prop: "invoiceNumber", name: gT("navOnline.management.InvoiceNumber"), width: 40 },
      { prop: "timestamp", name: gT("navOnline.management.Timestamp"), cellTemplate: this.dateTimeTemplate, width: 40 },
      { prop: "status", name: gT("navOnline.management.Status"), width: 40 },
      { name: gT("navOnline.management.Resend"), width: 40, cellTemplate: this.resendTemplate, resizeable: false, canAutoResize: true, sortable: false, draggable: false },
      { prop: "content", name: gT("navOnline.management.Content"), width: 40, sortable: false, },
    ];

  }

  public ngAfterViewInit() {


  }

  public getNavOnline() {
    if (!this.companyId) {
      return;
    }

    if (this.canModifyNavOnline) {
      this.masterDataService.getNavOnline(this.companyId, this.dateFrom, this.dateTo)
        .subscribe((result) => this.onNavOnlineDataLoadSuccessful(result[0]), (error) => this.onNavOnlineDataLoadFailed(error));
    }
  }

  public editNavOnline(companyId: number) {
    this.navOnline = new NavOnline();
    this.companyId = companyId;
    this.getNavOnline();
  }

  public resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  private edit() {
    if (!this.navOnline) {
      this.navOnline = new NavOnline();
      this.companyId = -1;
    }
  }

  public save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Saving"));

    this.navOnline.cegId = this.companyId;
    this.masterDataService.createOrUpdateNavOnline(this.navOnline).subscribe((navOnlineId) => this.saveSuccessHelper(navOnlineId), (error) => this.saveFailedHelper(error));
  }

  private onNavOnlineDataLoadSuccessful(navOnline: NavOnline): void {
    if (navOnline) {
      Object.assign(this.navOnline, navOnline);
      Object.assign(this.navOnlineInit, navOnline);
    }
  }

  private saveSuccessHelper(navOnlineId: number) {
    this.navOnline.id = navOnlineId;
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.translation("serviceMessage.UpdateSuccess")}`, MessageSeverity.success);

    if (this.changesSavedCallback) {
      this.changesSavedCallback(this.navOnline);
    }

    this.resetForm();
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    const errorMessage = Utilities.getHttpResponseMessage(error);
    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UpdateError"), MessageSeverity.error, errorMessage);
    this.alertService.showStickyMessage(errorMessage, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  public cancel() {
    this.navOnline = new NavOnline();
    this.companyId = -1;

    this.resetForm();

    this.alertService.showMessage(this.translation("serviceMessage.Cancelled"), this.translation("serviceMessage.TaskCancelled"), MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  public close() {
    this.navOnline = new NavOnline();
    this.companyId = -1;

    this.resetForm();

    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  private onNavOnlineDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    if (error) {
      this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.LoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.error, error);
    }
  }

  public showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }

  get canModifyNavOnline() {
    return Utilities.userHasRole(this.authService.currentUser, "NavOnlineModify");
  }

  public onCheckboxChangeForImmediate($event: any) {
    if (!$event.target.checked) {
      this.navOnline.dailyEmailNotification = true;
    }
  }

  public onCheckboxChangeForDaily($event: any) {
    if (!$event.target.checked) {
      this.navOnline.immediateEmailNotification = true;
    }
  }
}

import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Options, ChangeContext, PointerType } from "ng5-slider";
import { interval, BehaviorSubject, Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { AuthService } from "../../services/auth.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { MasterDataService } from "../../services/master-data.service";
import { Utilities } from "../../services/utilities";
import { NavStatus } from "../../models/nav-status.model";
import { Permission } from "../../models/permission.model";

@Component({
  selector: "nav-management",
  templateUrl: "./nav-management.component.html",
  styleUrls: ["./nav-management.component.scss"]
})
export class NavManagementComponent implements OnInit, AfterViewInit {
  public columns: any[] = [];
  public rows: NavStatus[] = [];
  public rowsCache: NavStatus[] = [];
  public minDate: Date;
  public maxDate: Date;
  public datePickerConfig: string;
  public loadingIndicator: boolean;
  public uniqueId: string = Utilities.uniqueId();

  private dateFrom: Date;
  private dateTo: Date;


  public sliderDisabled = true;
  public sliderValue = 60;
  public sliderOptions: Options = {
    floor: 5,
    ceil: 300,
    step: 5,
    disabled: false,
    showTicks: true,
    ticksArray: [10, 20, 30, 40, 50, 60, 90, 120, 150, 180, 210, 240, 270, 300]
  };

  private interval$: BehaviorSubject<number> = new BehaviorSubject(this.sliderValue * 1000);

  @ViewChild("indexTemplate", { static: true })
  public indexTemplate: TemplateRef<any>;

  @ViewChild("resendTemplate", { static: true })
  public resendTemplate: TemplateRef<any>;

  @ViewChild("dateTimeTemplate", { static: true })
  public dateTimeTemplate: TemplateRef<any>;

  constructor(private alertService: AlertService,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private masterDataService: MasterDataService) {
  }


  public ngOnInit() {
    this.datePickerConfig = "{ rangeInputFormat: 'YYYY-MM-DD', showWeekNumbers: true }";
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 600);
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.dateFrom.setDate(this.dateFrom.getDate() - 31);
    this.dateTo.setDate(this.dateTo.getDate() + 1);

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      { prop: "adoSzamHU", name: gT("navOnline.management.adoSzamHU"), width: 30 },
      { prop: "companyName", name: gT("navOnline.management.companyName"), width: 50 },
      { prop: "invoiceNumber", name: gT("navOnline.management.InvoiceNumber"), width: 40 },
      { prop: "timestamp", name: gT("navOnline.management.Timestamp"), cellTemplate: this.dateTimeTemplate, width: 40 },
      { prop: "status", name: gT("navOnline.management.Status"), width: 20 },
      { prop: "content", name: gT("navOnline.management.Content"), width: 80, sortable: false, },
      { name: gT("navOnline.management.Resend"), width: 40, cellTemplate: this.resendTemplate, resizeable: false, canAutoResize: true, sortable: false, draggable: false },
    ];

    this.loadData();

    this.interval$.pipe(
      // Assuming that the value provided by the input is in seconds
      switchMap(value => interval(value * 1000)),
      tap(() => this.refresh())
    )
      .subscribe();

    this.interval$.next(this.sliderValue);
  }

  public ngAfterViewInit() {

  }

  public loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.refresh();
  }

  public resend(row: NavStatus) {
    this.masterDataService.resendNavOnline(row.id).subscribe((result) => this.saveSuccessResend(result), (error) => this.saveFailedHelper(error));
  }

  public refresh() {
    this.masterDataService.getNavOnlineStatusByUser(this.dateFrom, this.dateTo)
      .subscribe((result) => this.onNavOnlineDataLoadSuccessful(result), (error) => this.onNavOnlineDataLoadFailed(error));
  }

  private onNavOnlineDataLoadSuccessful(navStatuses: NavStatus[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    if (navStatuses) {
      this.rowsCache = [...navStatuses];
      this.rows = navStatuses;
    }
  }

  private onNavOnlineDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    if (error) {
      this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.LoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.error, error);
    }
  }

  private saveSuccessResend(result: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage(this.translation("serviceMessage.Success"), null, MessageSeverity.info);
  }

  private saveSuccessHelper(navOnlineId: number) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.translation("serviceMessage.UpdateSuccess")}`, MessageSeverity.success);
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    const errorMessage = Utilities.getHttpResponseMessage(error);
    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UpdateError"), MessageSeverity.error, errorMessage);
    this.alertService.showStickyMessage(errorMessage, null, MessageSeverity.error);
  }

  public onDateChange($event: any) {
    this.dateFrom = $event[0];
    this.dateTo = $event[1];
    this.refresh();
  }

  public onChangeDisabled(): void {
    this.sliderOptions = Object.assign({}, this.sliderOptions, { disabled: this.sliderDisabled });
    if (!this.sliderDisabled) {
      this.setRefreshTimer(this.sliderValue);
    }
  }

  public onUserChangeSliderValue(changeContext: ChangeContext): void {
    this.setRefreshTimer(changeContext.value);
  }

  private setRefreshTimer(value: number) {
    this.interval$.next(value);
  }
  // public onKeyDown(event: { keyCode: number; }) {
  //   if (event.keyCode === 13) {
  //     this.refresh();
  //   }
  // }

  public getRowClass(row: { status: string; }) {
    if (row.status.toUpperCase() === "PROCESSING" || row.status.toUpperCase() === "INPROGRESS") {
      return { "row-status-inprogress": true };
    } else if (row.status.toUpperCase() === "FAILED") {
      return { "row-status-failed": true };
    } else if (row.status.toUpperCase() === "ABORTED") {
      return { "row-status-aborted": true };
    }
  }

  public onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter((r) => Utilities.searchArray(value, false, r.adoSzamHU, r.companyName, r.invoiceNumber, r.requestId, r.status));
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }
}

<div>
  <form *ngIf="formResetToggle" name="groupEditorForm" #f="ngForm" novalidate
    (ngSubmit)="f.form.valid ? save() :
          (!groupName.valid && showErrorAlert('Csoport név megadása kötelező', 'Kérem adjon meg egy csoport nevet (minimum 2, maximum 200 karakter hosszú legyen)'));">

    <div class="form-group row has-feedback-icon">
      <label class="col-form-label col-lg-3" for="groupName">{{'groups.editor.Name' | translate}}</label>
      <div class="col-lg-9">
        <input [disabled]="!canManageGroups" type="text" id="groupName" name="groupName"
          placeholder="A csoport neve kötelező adat ami legalább 2, de legfeljebb 200 karakterből áll." class="form-control"
          [ngClass]="{'is-valid': f.submitted && groupName.valid, 'is-invalid' : f.submitted && !groupName.valid}" [(ngModel)]="groupEdit.name"
          #groupName="ngModel" required minlength="2" maxlength="200" />
        <i *ngIf="showValidationErrors && f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': groupName.valid, 'fa-times' : !groupName.valid}" aria-hidden="true"></i>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="groupDescription">{{'groups.editor.Description' | translate}}</label>
      <div class="col-lg-9">
        <input [disabled]="!canManageGroups" type="text" id="groupDescription" name="groupDescription"
          placeholder="Ide tetszőleges további leírást adhat meg." class="form-control" [(ngModel)]="groupEdit.description" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <span *ngIf="showValidationErrors && f.submitted && !groupName.valid" class="invalid-feedback groupErrorMessage">
          {{'groups.editor.GroupNameRequired' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 card card-body bg-light rolesRow">
        <span>{{'groups.editor.RoleList' | translate}}</span>
        <custom-dual-list [source]="allRoles" [(destination)]="selectedRoles" [key]="dualListBoxKeyName" [display]="dualListBoxDisplayName"
          [format]="format" height="285px" [filter]="true">
        </custom-dual-list>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr class="edit-last-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <div ngPreserveWhitespaces class="float-right">
          <button *ngIf="!canManageGroups" type="button" (click)="cancel()" class="btn btn-default">{{'groups.editor.Close' | translate}}</button>
          <button *ngIf="canManageGroups" type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i>
            {{'groups.editor.Cancel' | translate}}</button>
          <button *ngIf="canManageGroups" type="submit" class="btn btn-primary" [disabled]="isSaving">
            <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i>
            {{isSaving ? ('groups.editor.Saving' | translate) : ('groups.editor.Save' | translate)}}
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </form>
</div>

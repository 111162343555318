import { Router } from "@angular/router";
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { MasterDataService } from "../../services/master-data.service";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { Utilities } from "../../services/utilities";
import { Company } from "../../models/company.model";
import { Permission } from "../../models/permission.model";
import { NavOnline } from "../../models/nav-online.model";

import { CompanyInfoComponent } from "./../controls/company-info.component";
import { NavOnlineInfoComponent } from "./../controls/nav-online-info.component";

@Component({
  selector: "company-management",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"]
})
export class CompanyManagementComponent implements OnInit, AfterViewInit {
  public columns: any[] = [];
  public rows: Company[] = [];
  public rowsCache: Company[] = [];
  public editedCompany: Company;
  public sourceCompany: Company;
  public editingCompanyName: { name: string };
  public loadingIndicator: boolean;

  @ViewChild("indexTemplate", { static: true })
  public indexTemplate: TemplateRef<any>;

  @ViewChild("companyNameTemplate", { static: true })
  public companyNameTemplate: TemplateRef<any>;

  @ViewChild("circleNameTemplate", { static: true })
  public circleNameTemplate: TemplateRef<any>;

  @ViewChild("actionsTemplate", { static: true })
  public actionsTemplate: TemplateRef<any>;

  @ViewChild("companyEditorModal", { static: true })
  public companyEditorModal: ModalDirective;

  @ViewChild("navOnlineEditorModal", { static: true })
  public navOnlineEditorModal: ModalDirective;

  @ViewChild("companyEditor", { static: true })
  public companyEditor: CompanyInfoComponent;

  @ViewChild("navOnlineEditor", { static: true })
  public navOnlineEditor: NavOnlineInfoComponent;

  @ViewChild("userForSuperAdministratorTemplate", { static: true })
  public userForSuperAdministratorTemplate: TemplateRef<any>;

  constructor(private alertService: AlertService,
    private translationService: AppTranslationService,
    private masterDataService: MasterDataService,
    private accountService: AccountService,
    private authService: AuthService,
    public router: Router) {
  }

  public ngOnInit() {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      { prop: "kod", name: gT("company.management.Kod"), width: 30 },
      { prop: "nev", name: gT("company.management.Nev"), width: this.canViewCircles ? 170 : 270, cellTemplate: this.companyNameTemplate },
      { prop: "adoSzamHU", name: gT("company.management.AdoAzon"), width: 60 },
    ];

    if (this.canViewCircles) {
      this.columns.push({ prop: "circleName", name: gT("company.management.CircleName"), width: 100, cellTemplate: this.userForSuperAdministratorTemplate });
    }

    if (this.actionsTemplate) {
      this.columns.push({ name: "", width: this.canViewNavOnline ? 260 : 190, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    }

    this.loadData();
  }

  public ngAfterViewInit() {
    this.companyEditor.changesSavedCallback = (company) => {

      if (company) {
        Object.assign(this.editedCompany, company);
      }

      this.addNewCompanyToList();
      this.companyEditorModal.hide();
    };

    this.companyEditor.changesCancelledCallback = () => {
      this.editedCompany = null;
      this.sourceCompany = null;
      this.companyEditorModal.hide();
    };

    this.navOnlineEditor.changesSavedCallback = (navOnline) => {
      this.navOnlineEditorModal.hide();
    };

    this.navOnlineEditor.changesCancelledCallback = () => {
      this.navOnlineEditorModal.hide();
    };
  }

  public addNewCompanyToList() {
    if (this.sourceCompany) {
      Object.assign(this.sourceCompany, this.editedCompany);

      let sourceIndex = this.rowsCache.indexOf(this.sourceCompany, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);
      }

      sourceIndex = this.rows.indexOf(this.sourceCompany, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);
      }

      this.editedCompany = null;
      this.sourceCompany = null;
    } else {
      const company = new Company();
      Object.assign(company, this.editedCompany);
      this.editedCompany = null;

      let maxIndex = 0;
      for (const comp of this.rowsCache) {
        if ((comp as any).index > maxIndex) {
          maxIndex = (comp as any).index;
        }
      }

      (company as any).index = maxIndex + 1;

      this.rowsCache.push(company);
      this.rows.push(company);
      this.rows = [...this.rows];
    }
  }

  public loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.masterDataService.getCompanies().subscribe((companies) => this.onDataLoadSuccessful(companies), (error) => this.onDataLoadFailed(error));
  }

  public onDataLoadSuccessful(companies: Company[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    companies.forEach((company, index, internalCompanies) => {
      (company as any).index = index + 1;
      // company.aktualisEv = companies.find(com => com.id == company.id);
    });

    this.rowsCache = [...companies];
    this.rows = companies;
  }

  public onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.LoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
      MessageSeverity.error, error);
  }

  public onSearchChanged(value: string) {
    if (this.canViewCircles) {
      this.rows = this.rowsCache.filter((r) => Utilities.searchArray(value, false, r.nev, r.kod, r.adoAzon, r.adoSzamHU, r.kozteruletNeve, r.rovidNev, r.circleName));
    } else {
      this.rows = this.rowsCache.filter((r) => Utilities.searchArray(value, false, r.nev, r.kod, r.adoAzon, r.adoSzamHU, r.kozteruletNeve, r.rovidNev));
    }
  }

  public onCompanyEditorModalHidden() {
    this.editingCompanyName = null;
    this.companyEditor.resetForm(true);
  }

  public onNavOnlineEditorModalHidden() {
    this.navOnlineEditor.resetForm(true);
  }

  public newCompany() {
    this.editingCompanyName = null;
    this.sourceCompany = null;
    this.editedCompany = this.companyEditor.newCompany();
    this.companyEditorModal.show();
  }

  public editCompany(row: Company) {
    this.editingCompanyName = { name: row.nev };
    this.sourceCompany = row;
    this.editedCompany = this.companyEditor.editCompany(row);
    this.companyEditorModal.show();
  }

  public editNavOnline(row: Company) {
    this.navOnlineEditor.editNavOnline(row.id);
    this.navOnlineEditorModal.show();
  }

  public deleteCompany(row: Company) {
    this.alertService.showDialog(`${this.translation("serviceMessage.ConfirmDelete")} ${row.nev}`, DialogType.confirm, () => this.deleteCompanyHelper(row));
  }

  public deleteCompanyHelper(row: Company) {
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Deleting"));
    this.loadingIndicator = true;

    this.masterDataService.deleteCompany(row.id)
      .subscribe((result) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        if (!result) {
          this.companyDeleteError();
        } else {
          this.rowsCache = this.rowsCache.filter((item) => item !== row);
          this.rows = this.rows.filter((item) => item !== row);
        }
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.companyDeleteError(error);
        });
  }

  public companyDeleteError(error?: any): void {
    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.DeleteError")}"${Utilities.getHttpResponseMessage(error)}"`,
      MessageSeverity.error, error);
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }

  get canViewCircles(): boolean {
    if (this.authService.currentUser) {
      return this.authService.currentUser.isSuperAdministrator;
    }
    return false;
  }

  get canAddNewCompany() {
    return Utilities.userHasRole(this.authService.currentUser, "CegAdd");
  }

  get canModifyCompany() {
    return Utilities.userHasRole(this.authService.currentUser, "CegEdit");
  }

  get canDeleteCompany() {
    return Utilities.userHasRole(this.authService.currentUser, "CegDelete");
  }

  get canViewNavOnline() {
    return Utilities.userHasRole(this.authService.currentUser, "NavOnline");
  }

  get canModifyNavOnline() {
    return Utilities.userHasRole(this.authService.currentUser, "NavOnlineModify");
  }
}

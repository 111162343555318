import { LOCALE_ID, NgModule, ErrorHandler } from "@angular/core";
import { DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ToastaModule } from "ngx-toasta";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { Ng5SliderModule } from "ng5-slider";
import { ChartsModule } from "ng2-charts";
import { CustomDualListModule } from "./components/controls/dual-list/custom-dual-list.module";
import { Angulartics2Module } from "angulartics2";
import { ApplicationInsightsModule, AppInsightsService } from "@markpieszak/ng-application-insights";
import { CookieLawModule } from "angular2-cookie-law";

import { AppRoutingModule } from "./app-routing.module";
import { AppErrorHandler } from "./app-error.handler";
import { AppTitleService } from "./services/app-title.service";
import { AppTranslationService, TranslateLanguageLoader } from "./services/app-translation.service";
import { ConfigurationService } from "./services/configuration.service";
import { AlertService } from "./services/alert.service";
import { ThemeManager } from "./services/theme-manager";
import { LocalStoreManager } from "./services/local-store-manager.service";
import { EndpointBase } from "./services/endpoint-base.service";
import { OidcHelperService } from "./services/oidc-helper.service";
import { NotificationService } from "./services/notification.service";
import { NotificationEndpoint } from "./services/notification-endpoint.service";
import { AccountService } from "./services/account.service";
import { AccountEndpoint } from "./services/account-endpoint.service";
import { MasterDataService } from "./services/master-data.service";
import { MasterDataEndpoint } from "./services/master-data-endpoint.service";

import { EqualValidator } from "./directives/equal-validator.directive";
import { LastElementDirective } from "./directives/last-element.directive";
import { AutofocusDirective } from "./directives/autofocus.directive";
import { BootstrapTabDirective } from "./directives/bootstrap-tab.directive";
import { BootstrapToggleDirective } from "./directives/bootstrap-toggle.directive";
import { BootstrapSelectDirective } from "./directives/bootstrap-select.directive";
import { BootstrapDatepickerDirective } from "./directives/bootstrap-datepicker.directive";
import { GroupByPipe } from "./pipes/group-by.pipe";

import { AppComponent } from "./components/app.component";
import { LegalComponent } from "./components/legal/legal.component";
import { LoginComponent } from "./components/login/login.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { HomeComponent } from "./components/home/home.component";
import { CompanyManagementComponent } from "./components/company/company.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { AdminComponent } from "./components/admin/admin.component";
import { AboutComponent } from "./components/about/about.component";
import { ContactComponent } from "./components/contact/contact.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AccountConfirmEmailComponent } from "./components/account/account-confirm-email.component";
import { AccountConfirmRegistratrionComponent } from "./components/account/account-confirm-registratrion.component";
import { FeaturesComponent } from "./components/features/features.component";
import { AccountingFeaturesComponent } from "./components/features/accounting/accounting-features.component";
import { AccountingManualComponent } from "./components/features/accounting/accounting-manual.component";
import { EinvoiceFeaturesComponent } from "./components/features/einvoice/einvoice-features.component";

import { NotificationsViewerComponent } from "./components/controls/notifications-viewer.component";
import { SearchBoxComponent } from "./components/controls/search-box.component";
import { CompanyInfoComponent } from "./components/controls/company-info.component";
import { NavOnlineInfoComponent } from "./components/controls/nav-online-info.component";
import { NavManagementComponent } from "./components/controls/nav-management.component";
import { UserInfoComponent } from "./components/controls/user-info.component";
import { UserPreferencesComponent } from "./components/controls/user-preferences.component";
import { UsersManagementComponent } from "./components/controls/users-management.component";
import { GroupManagementComponent } from "./components/controls/group-management.component";
import { GroupEditorComponent } from "./components/controls/group-editor.component";
import { CircleManagementComponent } from "./components/controls/circle-management.component";
import { CircleEditorComponent } from "./components/controls/circle-editor.component";

@NgModule({
  imports: [
    CustomDualListModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    Angulartics2Module.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader,
      }
    }),
    ApplicationInsightsModule.forRoot({
      instrumentationKey: "a3525ea4-50dd-4013-b85e-37b766addb5d",
      autoTrackPageVisitTime: true,
      disableTelemetry: false,
    }),
    NgxDatatableModule,
    Ng5SliderModule,
    ToastaModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    ChartsModule,
    CookieLawModule,
  ],
  declarations: [
    AppComponent,
    LegalComponent,
    LoginComponent,
    SignUpComponent,
    HomeComponent,
    CompanyManagementComponent, CompanyInfoComponent, NavOnlineInfoComponent,
    SettingsComponent,
    AdminComponent,
    UsersManagementComponent, UserInfoComponent, UserPreferencesComponent,
    GroupManagementComponent, GroupEditorComponent,
    NavManagementComponent,
    CircleManagementComponent, CircleEditorComponent,
    AboutComponent, ContactComponent,
    FeaturesComponent, AccountingFeaturesComponent, AccountingManualComponent,
    EinvoiceFeaturesComponent,
    NotFoundComponent,
    AccountConfirmEmailComponent,
    AccountConfirmRegistratrionComponent,
    NotificationsViewerComponent,
    SearchBoxComponent,
    EqualValidator,
    LastElementDirective,
    AutofocusDirective,
    BootstrapTabDirective,
    BootstrapToggleDirective,
    BootstrapSelectDirective,
    BootstrapDatepickerDirective,
    GroupByPipe,
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: LOCALE_ID, useValue: "hu" },
    DatePipe,
    AlertService,
    ThemeManager,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AccountService,
    AccountEndpoint,
    MasterDataService,
    MasterDataEndpoint,
    LocalStoreManager,
    EndpointBase,
    OidcHelperService,
    AppInsightsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

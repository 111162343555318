import { Injectable } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { MasterDataEndpoint } from "./master-data-endpoint.service";
import { AuthService } from "./auth.service";

import { Company } from "./../models/company.model";
import { CompanyAddress } from "./../models/company-address.model";
import { NavOnline } from "../models/nav-online.model";
import { NavStatus } from "../models/nav-status.model";

@Injectable()
export class MasterDataService {

  constructor(private router: Router, private http: HttpClient, private authService: AuthService,
    private masterDataEndpoint: MasterDataEndpoint) {
  }

  public getNavOnline(companyId: number, dateFrom?: Date, dateTo?: Date) {
    return forkJoin(
      this.masterDataEndpoint.getNavOnlineByCompanyIdEndpoint<NavOnline>(companyId),
      this.masterDataEndpoint.getNavStatusByCompanyIdEndpoint<NavStatus[]>(companyId, dateFrom, dateTo));
  }

  public getNavOnlineStatus(companyId: number, dateFrom?: Date, dateTo?: Date) {
    return this.masterDataEndpoint.getNavStatusByCompanyIdEndpoint<NavStatus[]>(companyId, dateFrom, dateTo);
  }

  public getNavOnlineStatusByUser(dateFrom?: Date, dateTo?: Date) {
    return this.masterDataEndpoint.getNavStatusEndpoint<NavStatus[]>(dateFrom, dateTo);
  }

  public resendNavOnline(navOnlineStatusId: number) {
    return this.masterDataEndpoint.getNavStatusResendEndpoint<number>(navOnlineStatusId);
  }

  public createOrUpdateNavOnline(navOnline: NavOnline): Observable<number> {
    return this.masterDataEndpoint.createOrUpdateNavOnlineEndpoint<number>(navOnline);
  }

  public getCompanies(page?: number, pageSize?: number): Observable<Company[]> {
    return this.masterDataEndpoint.getCompaniesEndpoint<Company[]>(page, pageSize);
  }

  public deleteCompany(companyId: number) {
    return this.masterDataEndpoint.getDeleteCompanyEndpoint(companyId);
  }

  public currentCompany(companyId: number): Observable<Company> {
    return this.masterDataEndpoint.getCurrentCompanyEndpoint<Company>(companyId);
  }

  public companyAddress(companyId: number): Observable<CompanyAddress> {
    return this.masterDataEndpoint.getCompanyAddressEndpoint<CompanyAddress>(companyId);
  }

  public updateCompany(company: Company, companyAddress: CompanyAddress) {
    return forkJoin(
      this.masterDataEndpoint.getUpdateCompanyEndpoint(company),
      this.masterDataEndpoint.getUpdateCompanyAddressEndpoint(companyAddress));
  }

  public createCompany(company: Company): Observable<number> {
    return this.masterDataEndpoint.getCreateCompanyEndpoint<number>(company);
  }

  public createCompanyAddress(companyAddress: CompanyAddress) {
    return this.masterDataEndpoint.getCreateCompanyAddressEndpoint(companyAddress);
  }

  public getUserCompanies(userId: number) {
    return forkJoin(
      this.masterDataEndpoint.getUserAvaliableCompaniesEndpoint<Company[]>(userId),
      this.masterDataEndpoint.getUserCompaniesEndpoint<number[]>(userId));
  }

  public updateUserCompanies(userId: number, companyIds: number[]) {
    return this.masterDataEndpoint.getUpdateUserCompaniesEndpoint(userId, companyIds);
  }
}

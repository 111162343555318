import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
// tslint:disable-next-line:import-blacklist
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { EndpointBase } from "./endpoint-base.service";
import { ConfigurationService } from "./configuration.service";

@Injectable()
export class AccountEndpoint extends EndpointBase {
  private readonly _usersUrl: string = "/api/users";
  private readonly _userByUserNameUrl: string = "/api/users/username";
  private readonly _currentUserUrl: string = "/api/users/me";
  private readonly _currentUserPreferencesUrl: string = "/api/users/me/preferences";
  private readonly _unblockUserUrl: string = "/api/users/unblock";
  private readonly _blockUserUrl: string = "/api/users/block";
  private readonly _groupsUrl: string = "/api/groups";
  private readonly _groupByGroupNameUrl: string = "/api/groups/name";
  private readonly _permissionsUrl: string = "/api/groups/permissions";
  private readonly _rolesUrl: string = "/api/roles";

  private readonly _confirmEmailUrl: string = "/api/account/confirm/email";
  private readonly _confirmRegistrationUrl: string = "/api/account/confirm/registration";

  private readonly _buildPropertiesUrl: string = "/api/account/buildProperties";

  private readonly _circlesUrl: string = "/api/circles";

  get usersUrl() { return this.configurations.baseUrl + this._usersUrl; }
  get userByUserNameUrl() { return this.configurations.baseUrl + this._userByUserNameUrl; }
  get currentUserUrl() { return this.configurations.baseUrl + this._currentUserUrl; }
  get currentUserPreferencesUrl() { return this.configurations.baseUrl + this._currentUserPreferencesUrl; }
  get unblockUserUrl() { return this.configurations.baseUrl + this._unblockUserUrl; }
  get blockUserUrl() { return this.configurations.baseUrl + this._blockUserUrl; }
  get groupsUrl() { return this.configurations.baseUrl + this._groupsUrl; }
  get groupByGroupNameUrl() { return this.configurations.baseUrl + this._groupByGroupNameUrl; }
  get permissionsUrl() { return this.configurations.baseUrl + this._permissionsUrl; }
  get rolesUrl() { return this.configurations.baseUrl + this._rolesUrl; }
  get confirmEmailUrl() { return this.configurations.baseUrl + this._confirmEmailUrl; }
  get confirmRegistrationUrl() { return this.configurations.baseUrl + this._confirmRegistrationUrl; }
  get getBuildPropertiesUrl() { return this.configurations.baseUrl + this._buildPropertiesUrl; }
  get cirlesUrl() { return this.configurations.baseUrl + this._circlesUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {
    super(http, configurations, injector);
  }

  public getBuildPropertiesEndpoint<T>(): Observable<T> {
    const header = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.get<T>(this.getBuildPropertiesUrl, { headers: header }).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getBuildPropertiesEndpoint());
      }));
  }

  public getUserEndpoint<T>(userId?: number): Observable<T> {
    const endpointUrl = userId ? `${this.usersUrl}/${userId}` : this.currentUserUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getUserEndpoint(userId));
      }));
  }

  public getUserByUserNameEndpoint<T>(userName: string): Observable<T> {
    const endpointUrl = `${this.userByUserNameUrl}/${userName}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getUserByUserNameEndpoint(userName));
      }));
  }

  public getUsersEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.usersUrl}/${page}/${pageSize}` : this.usersUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getUsersEndpoint(page, pageSize));
      }));
  }

  public getNewUserEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this.usersUrl, JSON.stringify(userObject), this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getNewUserEndpoint<T>(userObject));
      }));
  }

  public getUpdateUserEndpoint<T>(userObject: any, userId?: number): Observable<T> {
    const endpointUrl = userId ? `${this.usersUrl}/${userId}` : this.currentUserUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getUpdateUserEndpoint(userObject, userId));
      }));
  }

  public getPatchUpdateUserEndpoint<T>(patch: {}, userId?: string): Observable<T>;
  public getPatchUpdateUserEndpoint<T>(value: any, op: string, path: string, from?: any, userId?: string): Observable<T>;
  public getPatchUpdateUserEndpoint<T>(valueOrPatch: any, opOrUserId?: string, path?: string, from?: any, userId?: string): Observable<T> {
    let endpointUrl: string;
    let patchDocument: {};

    if (path) {
      endpointUrl = userId ? `${this.usersUrl}/${userId}` : this.currentUserUrl;
      patchDocument = from ?
        [{ value: valueOrPatch, path, op: opOrUserId, from }] :
        [{ value: valueOrPatch, path, op: opOrUserId }];
    } else {
      endpointUrl = opOrUserId ? `${this.usersUrl}/${opOrUserId}` : this.currentUserUrl;
      patchDocument = valueOrPatch;
    }

    return this.http.patch(endpointUrl, JSON.stringify(patchDocument), this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getPatchUpdateUserEndpoint(valueOrPatch, opOrUserId, path, from, userId));
      }));
  }

  public getUserPreferencesEndpoint<T>(): Observable<T> {
    return this.http.get<T>(this.currentUserPreferencesUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
          return this.handleError(error, () => this.getUserPreferencesEndpoint());
      }));
  }

  public getUpdateUserPreferencesEndpoint<T>(configuration: string): Observable<T> {
    return this.http.put<T>(this.currentUserPreferencesUrl, JSON.stringify(configuration), this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getUpdateUserPreferencesEndpoint(configuration));
      }));
  }

  public getUnblockUserEndpoint<T>(userId: number): Observable<T> {
    const endpointUrl = `${this.unblockUserUrl}/${userId}`;

    return this.http.put<T>(endpointUrl, null, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getUnblockUserEndpoint(userId));
      }));
  }

  public getBlockUserEndpoint<T>(userId: number): Observable<T> {
    const endpointUrl = `${this.blockUserUrl}/${userId}`;

    return this.http.put<T>(endpointUrl, null, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getBlockUserEndpoint(userId));
      }));
  }

  public getDeleteUserEndpoint<T>(userId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/${userId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getDeleteUserEndpoint(userId));
      }));
  }

  public getGroupEndpoint<T>(groupId: string): Observable<T> {
    const endpointUrl = `${this.groupsUrl}/${groupId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getGroupEndpoint(groupId));
      }));
  }

  public getGroupByGroupNameEndpoint<T>(groupName: string): Observable<T> {
    const endpointUrl = `${this.groupByGroupNameUrl}/${groupName}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getGroupByGroupNameEndpoint(groupName));
      }));
  }

  public getGroupsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.groupsUrl}/${page}/${pageSize}` : this.groupsUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getGroupsEndpoint(page, pageSize));
      }));
  }

  public getNewGroupEndpoint<T>(groupObject: any): Observable<T> {
    return this.http.post<T>(this.groupsUrl, JSON.stringify(groupObject), this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getNewGroupEndpoint(groupObject));
      }));
  }

  public getUpdateGroupEndpoint<T>(groupObject: any, groupId: number): Observable<T> {
    const endpointUrl = `${this.groupsUrl}/${groupId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(groupObject), this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getUpdateGroupEndpoint(groupObject, groupId));
      }));
  }

  public getDeleteGroupEndpoint<T>(groupId: number): Observable<T> {
    const endpointUrl = `${this.groupsUrl}/${groupId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getDeleteGroupEndpoint(groupId));
      }));
  }

  public getPermissionsEndpoint<T>(): Observable<T> {
    return this.http.get<T>(this.permissionsUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getPermissionsEndpoint());
      }));
  }

  public getRolesEndpoint<T>(): Observable<T> {
    return this.http.get<T>(this.rolesUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getRolesEndpoint());
      }));
  }

  public getConfirmEmailEndpoint(userId: number, code: string) {
    const header = new HttpHeaders({ "Content-Type": "application/json" });

    const params = new HttpParams()
      .append("userId", userId.toString())
      .append("code", code);

    return this.http.get(this.confirmEmailUrl, { headers: header, params }).pipe(
      catchError((error) => {
        return this.handleError(error, () => this.getConfirmEmailEndpoint(userId, code));
      }));
  }

  public getRegistrationConfirmedEndpoint(userId: number) {
    const header = this.getHeaders();

    const params = new HttpParams()
      .append("userId", userId.toString());

    return this.http.get(this.confirmRegistrationUrl, { headers: header, params }).pipe(
      catchError((error) => {
        return this.handleError(error, () => this.getRegistrationConfirmedEndpoint(userId));
      }));
  }

  public getCirclesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.cirlesUrl}/${page}/${pageSize}` : this.cirlesUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getCirclesEndpoint(page, pageSize));
      }));
  }

  public getCompaniesByCircleIdEndpoint<T>(circleId: number): Observable<T> {
    const endpointUrl = `${this.cirlesUrl}/${circleId}/companies`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getCompaniesByCircleIdEndpoint(circleId));
      }));
  }

  public getNewCircleEndpoint<T>(circleObject: any): Observable<T> {
    return this.http.post<T>(this.cirlesUrl, JSON.stringify(circleObject), this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getNewCircleEndpoint(circleObject));
      }));
  }

  public getUpdateCircleEndpoint(circleObject: any, circleId: number) {
    const endpointUrl = `${this.cirlesUrl}/${circleId}`;

    return this.http.put(endpointUrl, JSON.stringify(circleObject), this.requestHeaders).pipe(
      catchError((error) => {
        return this.handleError(error, () => this.getUpdateCircleEndpoint(circleObject, circleId));
      }));
  }

  public getDeleteCircleEndpoint(circleId: number) {
    const endpointUrl = `${this.cirlesUrl}/${circleId}`;

    return this.http.delete(endpointUrl, this.requestHeaders).pipe(
      catchError((error) => {
        return this.handleError(error, () => this.getDeleteCircleEndpoint(circleId));
      }));
  }
}

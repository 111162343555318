import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationEndpoint {
    private demoNotifications = [];

    public getNotificationEndpoint<T>(notificationId: number): Observable<T> {
        const notification = this.demoNotifications.find((val) => val.id === notificationId);
        let response: HttpResponse<T>;

        if (notification) {
            response = this.createResponse<T>(notification, 200);
        } else {
            response = this.createResponse<T>(null, 404);
        }

        return of(response.body);
    }

    public getNotificationsEndpoint<T>(page: number, pageSize: number): Observable<T> {
        const notifications = this.demoNotifications;
        const response = this.createResponse<T>(this.demoNotifications, 200);

        return of(response.body);
    }

    public getUnreadNotificationsEndpoint<T>(userId?: string): Observable<T> {
        const unreadNotifications = this.demoNotifications.filter((val) => !val.isRead);
        const response = this.createResponse<T>(unreadNotifications, 200);

        return of(response.body);
    }

    public getNewNotificationsEndpoint<T>(lastNotificationDate?: Date): Observable<T> {
        const unreadNotifications = this.demoNotifications;
        const response = this.createResponse<T>(unreadNotifications, 200);

        return of(response.body);
    }

    public getPinUnpinNotificationEndpoint<T>(notificationId: number, isPinned?: boolean, ): Observable<T> {
        const notification = this.demoNotifications.find((val) => val.id === notificationId);
        let response: HttpResponse<T>;

        if (notification) {
            response = this.createResponse<T>(null, 204);

            if (isPinned == null) {
                isPinned = !notification.isPinned;
            }

            notification.isPinned = isPinned;
            notification.isRead = true;
        } else {
            response = this.createResponse<T>(null, 404);
        }

        return of(response.body);
    }

    public getReadUnreadNotificationEndpoint<T>(notificationIds: number[], isRead: boolean, ): Observable<T> {
        for (const notificationId of notificationIds) {
            const notification = this.demoNotifications.find((val) => val.id === notificationId);

            if (notification) {
                notification.isRead = isRead;
            }
        }

        const response = this.createResponse<T>(null, 204);
        return of(response.body);
    }

    public getDeleteNotificationEndpoint<T>(notificationId: number): Observable<T> {
        const notification = this.demoNotifications.find((val) => val.id === notificationId);
        let response: HttpResponse<T>;

        if (notification) {
            this.demoNotifications = this.demoNotifications.filter((val) => val.id !== notificationId);
            response = this.createResponse<T>(notification, 200);
        } else {
            response = this.createResponse<T>(null, 404);
        }

        return of(response.body);
    }

    private createResponse<T>(body, status: number) {
        return new HttpResponse<T>({ body, status });
    }
}

<div>
  <div class="row">
    <div class="col-md-8">
      <!--<label class="col-form-label">{{'navOnline.management.RefreshInterval' | translate}}</label>-->
      <ng5-slider [(value)]="sliderValue" [options]="sliderOptions" (userChange)="onUserChangeSliderValue($event)"></ng5-slider>
    </div>
  </div>
  <div class="row control-box">
    <div class="col-xs-12 col-12 col-md-4 form-group">
      <!-- [(ngModel)]="navOnline.daterangepicker" #password="ngModel" -->
      <!-- https://github.com/valor-software/ngx-bootstrap/blob/development/src/datepicker/bs-datepicker.config.ts#L31 -->
      <input type="text" attr.id="daterangepicker-{{uniqueId}}" [minDate]="minDate" [maxDate]="maxDate" [bsConfig]="datePickerConfig"
        (bsValueChange)="onDateChange($event)" placeholder="{{'navOnline.management.Daterangepicker' | translate}}" class="form-control"
        bsDaterangepicker>
    </div>
    <div class="col-lg-8">
      <div class="form-group search-box">
        <search-box (searchChange)="onSearchChanged($event)" placeholder="{{'groups.management.Search' | translate}}">
        </search-box>
      </div>
    </div>
  </div>

  <ngx-datatable class="material colored-header sm table table-striped table-hover" [loadingIndicator]="loadingIndicator" [rows]="rows"
    [rowHeight]="35" [columnMode]="'force'" [headerHeight]="35" [footerHeight]="35" [columns]="columns" [scrollbarV]="true" [rowClass]="getRowClass"
    [sorts]="[{prop: 'timestamp', dir: 'desc'}]">
  </ngx-datatable>

  <ng-template #dateTimeTemplate let-row="row" let-value="value">
    {{value | date:'yyyy.MM.dd HH:mm:ss' }}
  </ng-template>

  <ng-template #resendTemplate let-row="row" let-value="value" let-i="index">
    <div
      *ngIf="row.status.toUpperCase() === 'PROCESSING' || row.status.toUpperCase() === 'INPROGRESS' || row.status.toUpperCase() === 'FAILED' || row.status.toUpperCase() === 'RECEIVED_FOR_LOCAL'">
      <a class="btn btn-link btn-sm" href="javascript:;" (click)="resend(row)"><i class="fa fa-retweet" aria-hidden="true"></i>
        {{'navOnline.management.Resend' | translate}}</a>
    </div>
  </ng-template>
</div>

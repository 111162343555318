<div class="container admin-page">
  <div [@fadeInOut]>
    <div class="d-sm-flex flex-row">
      <ul bootstrapTab #tab="bootstrap-tab" (showBSTab)="onShowTab($event)" class="nav nav-tabs nav-tabs--vertical nav-tabs--left" role="navigation">
        <li class="nav-item">
          <a id="companiesTab" [routerLink]="[]" fragment="companies" data-target="#companies" href="#companies" class="nav-link active"
            data-toggle="tab" role="tab" aria-controls="companies">
            <i class="fa fa-building fa-fw" aria-hidden="true"></i> {{'admin.tab.Companies' | translate}}
          </a>
        </li>
        <li class="nav-item" [hidden]="!canViewNavStatus">
          <a id="navStatusTab" [routerLink]="[]" fragment="nav-status" data-target="#nav-status" href="#nav-status" class="nav-link" data-toggle="tab"
            role="tab" aria-controls="nav-status">
            <i class="fa fa-users fa-fw" aria-hidden="true"></i> {{'admin.tab.NavStatus' | translate}}
          </a>
        </li>
        <li class="nav-item" [hidden]="!canViewUsers">
          <a id="usersTab" [routerLink]="[]" fragment="users" data-target="#users" href="#users" class="nav-link" data-toggle="tab" role="tab"
            aria-controls="users">
            <i class="fa fa-users fa-fw" aria-hidden="true"></i> {{'admin.tab.Users' | translate}}
          </a>
        </li>
        <li class="nav-item" [hidden]="!canViewGroups">
          <a id="groupsTab" [routerLink]="[]" fragment="groups" data-target="#groups" href="#groups" class="nav-link" data-toggle="tab" role="tab"
            aria-controls="groups">
            <i class="fa fa-shield fa-fw" aria-hidden="true"></i> {{'admin.tab.Groups' | translate}}
          </a>
        </li>
        <li class="nav-item" [hidden]="!canViewCircles">
          <a id="circlesTab" [routerLink]="[]" fragment="circles" data-target="#circles" href="#circles" class="nav-link" data-toggle="tab" role="tab"
            aria-controls="circles">
            <i class="fa fa-user-circle fa-fw" aria-hidden="true"></i> {{'admin.tab.Circles' | translate}}
          </a>
        </li>
      </ul>
      <div class="tab-content w-100">
        <div class="tab-pane show active" id="companies" role="tabpanel">
          <h4>{{'admin.header.CompanyManagement' | translate}}</h4>
          <hr class="separator-hr" />
          <div [@fadeInOut] *ngIf="isCompaniesActivated" class="content-container pl-lg-1">
            <company-management></company-management>
          </div>
        </div>
        <div class="tab-pane" id="nav-status" role="tabpanel">
          <h4>{{'admin.header.NavManagement' | translate}}</h4>
          <hr class="separator-hr" />
          <div [@fadeInOut] *ngIf="canViewNavStatus && isNavStatusActivated" class="content-container pl-lg-1">
            <nav-management></nav-management>
          </div>
        </div>
        <div class="tab-pane" id="users" role="tabpanel">
          <h4>{{'admin.header.UserManagement' | translate}}</h4>
          <hr class="separator-hr" />
          <div [@fadeInOut] *ngIf="canViewUsers && isUsersActivated" class="content-container pl-lg-1">
            <users-management></users-management>
          </div>
        </div>

        <div class="tab-pane" id="groups" role="tabpanel">
          <h4>{{'admin.header.GroupManagement' | translate}}</h4>
          <hr class="separator-hr" />
          <div [@fadeInOut] *ngIf="canViewGroups && isGroupsActivated" class="content-container pl-lg-1">
            <group-management></group-management>
          </div>
        </div>

        <div class="tab-pane" id="circles" role="tabpanel">
          <h4>{{'admin.header.CircleManagement' | translate}}</h4>
          <hr class="separator-hr" />
          <div [@fadeInOut] *ngIf="canViewCircles && isCirclesActivated" class="content-container pl-lg-1">
            <circle-management></circle-management>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

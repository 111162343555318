import { Component } from "@angular/core";
import { fadeInOut } from "../../../services/animations";

@Component({
    selector: "accounting-manual",
    templateUrl: "./accounting-manual.component.html",
    styleUrls: ["./accounting-manual.component.scss"],
    animations: [fadeInOut],
})
export class AccountingManualComponent {
}
<div class="d-flex h-90">
  <div class="login-container m-auto">
    <div class="card boxshadow">
      <div class="card-header bg-primary dark text-white clearfix">
        <i class="fa fa-lock" aria-hidden="true"></i> Felhasználó regisztrációja
        <button *ngIf="isModal" type="button" class="close float-right text-light" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="card-body">
        <div class="col-md-8 offset-md-2">
          <form *ngIf="formResetToggle" class="form-horizontal login" name="signUpForm" #f="ngForm" novalidate
            (ngSubmit)="f.form.valid ? signUp() :
                          (lastName.errors && lastName.errors.required && showErrorAlert('Vezetéknév megadása kötelező.', 'Kérjük, hogy adja meg az adatot!'));
                          (firstName.errors && firstName.errors.required && showErrorAlert('Keresztnév megadása kötelező.', 'Kérjük, hogy adja meg az adatot!'));
                          (userName.errors && userName.errors.required && showErrorAlert('Felhasználó név megadása kötelező.', 'Kérjük, hogy adja meg az adatot!'));
                          (email.errors && email.errors.required && showErrorAlert('Email megadása kötelező.', 'Kérem valós email címet adjon meg!'));
                          (newPassword.errors && newPassword.errors.required && showErrorAlert('Jelszó megadása kötelező.', 'Kérem, hogy érvényes jelszót adjon meg!'))">

            <div class="form-group row has-feedback-icon">
              <label class="col-form-label col-lg-3" for="sign-up-lastName">Vezetéknév:</label>
              <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && lastName.valid, 'is-invalid' : f.submitted && !lastName.valid}">
                <input type="text" id="sign-up-lastName" name="lastName" placeholder="" class="form-control" [(ngModel)]="userSignUp.lastName"
                  #lastName="ngModel" required minlength="3" />
                <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': lastName.valid, 'fa-times' : !lastName.valid}"
                  aria-hidden="true"></span>
                <span class="invalid-feedback" *ngIf="f.submitted && lastName.errors && lastName.errors.required">
                  Vezetéknév megadása kötelező.
                </span>
                <span class="invalid-feedback" *ngIf="f.submitted && lastName.errors && lastName.errors.minlength">
                  Minimum 3 karakter megadása kötelező!
                </span>
              </div>
            </div>

            <div class="form-group row has-feedback-icon">
              <label class="col-form-label col-lg-3" for="sign-up-firstName">Keresztnév:</label>
              <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && firstName.valid, 'is-invalid' : f.submitted && !firstName.valid}">
                <input type="text" id="sign-up-firstName" name="firstName" placeholder="" class="form-control" [(ngModel)]="userSignUp.firstName"
                  #firstName="ngModel" required minlength="3" />
                <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': firstName.valid, 'fa-times' : !firstName.valid}"
                  aria-hidden="true"></span>
                <span class="invalid-feedback" *ngIf="f.submitted && firstName.errors && firstName.errors.required">
                  Keresztnév megadása kötelező.
                </span>
                <span class="invalid-feedback" *ngIf="f.submitted && firstName.errors && firstName.errors.minlength">
                  Minimum 3 karakter megadása kötelező!
                </span>
              </div>
            </div>

            <div class="form-group row has-feedback-icon">
              <label class="col-form-label col-lg-3" for="sign-up-userName">Felhasználó név:</label>
              <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && userName.valid, 'is-invalid' : f.submitted && !userName.valid}">
                <input type="text" id="sign-up-userName" name="userName" placeholder="A felhasználó ezzel a névvel tud belépni a rendszerbe."
                  class="form-control" [(ngModel)]="userSignUp.userName" #userName="ngModel" required minlength="4" />
                <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': userName.valid, 'fa-times' : !userName.valid}"
                  aria-hidden="true"></span>
                <span class="invalid-feedback" *ngIf="f.submitted && userName.errors && userName.errors.required">
                  Felhasználó név megadása kötelező.
                </span>
                <span class="invalid-feedback" *ngIf="f.submitted && userName.errors && userName.errors.minlength">
                  Minimum 4 karakter megadása kötelező!
                </span>
              </div>
            </div>

            <div class="form-group row has-feedback-icon">
              <label class="col-form-label col-lg-3" for="sign-up-email">Email cím:</label>
              <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && email.valid, 'is-invalid' : f.submitted && !email.valid}">
                <input type="email" id="sign-up-email" name="email" placeholder="Adjon meg egy érvényes email címet!" class="form-control"
                  [(ngModel)]="userSignUp.email" #email="ngModel" required pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': email.valid, 'fa-times' : !email.valid}"
                  aria-hidden="true"></span>
                <span class="invalid-feedback" *ngIf="f.submitted && email.errors && email.errors.required">
                  Email cím megadása kötelező.
                </span>
                <span class="invalid-feedback" *ngIf="f.submitted && email.errors && email.errors.pattern">
                  Hibás formátum. A helyes formátum <i>john@doe.com</i>
                </span>
              </div>
            </div>

            <div class="form-group row has-feedback-icon">
              <label class="col-form-label col-lg-3" for="sign-up-newPassword">Jelszó:</label>
              <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && newPassword.valid, 'is-invalid' : f.submitted && !newPassword.valid}">
                <i class="fa fa-info" aria-hidden="true"
                  tooltip="Jelszó pattern: Legalább 6 kerakter hosszú legyen, tartalmazzon legalább 1 számot, 1 kisbetüt és 1 nagybetüt."></i>
                <input type="password" id="sign-up-newPassword" name="newPassword" placeholder="Jelszó bevitele" class="form-control"
                  [(ngModel)]="userSignUp.newPassword" #newPassword="ngModel" required validateEqual="confirmPassword" reverse="true" minlength="6" />
                <span *ngIf="f.submitted" class="fa form-control-feedback"
                  [ngClass]="{'fa-check': newPassword.valid, 'fa-times' : !newPassword.valid}" aria-hidden="true"></span>
                <span class="invalid-feedback" *ngIf="f.submitted && newPassword.errors && newPassword.errors.required">
                  Jelszó megadása kötelező.
                </span>
                <span class="invalid-feedback" *ngIf="f.submitted && newPassword.errors && newPassword.errors.minlength">
                  Minimum 6 karakter megadása szükséges!
                </span>
              </div>
            </div>
            <div class="form-group row has-feedback-icon">
              <label class="col-form-label col-lg-3" for="sign-up-confirmPassword">Jelszó megerősítése:</label>
              <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && newPassword.valid, 'is-invalid' : f.submitted && !confirmPassword.valid}">
                <input type="password" id="sign-up-confirmPassword" name="confirmPassword" placeholder="Jelszó bevitele" class="form-control"
                  [(ngModel)]="userSignUp.confirmPassword" #confirmPassword="ngModel" required validateEqual="password" minlength="6" />
                <span *ngIf="f.submitted" class="fa form-control-feedback"
                  [ngClass]="{'fa-check': confirmPassword.valid, 'fa-times' : !confirmPassword.valid}" aria-hidden="true"></span>
                <span class="invalid-feedback" *ngIf="f.submitted && confirmPassword.errors && confirmPassword.errors.required">
                  Jelszó megadása kötelező.
                </span>
                <span class="invalid-feedback" *ngIf="f.submitted && confirmPassword.errors && confirmPassword.errors.validateEqual">
                  A beírt jelszavak nem egyeznek meg.
                </span>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <p>
                  A regisztrációval Ön elismeri, hogy elolvasta és megértette a Novaplan Szoftver Kft. Általános Szolgáltatási Feltételeit.
                </p>
                <p>
                  A fent megadott címre küldött emailben található linken meg kell erősítenie regisztrációját.
                  A regisztráció jóváhagyásáról emailt küldünk Önnek.
                  A rendszert a regisztráció megerősítése és a jóváhagyás után tudja használni.
                </p>
              </div>
            </div>

            <div class="form-group">
              <div class="offset-md-4 col-md-8">
                <button type="submit" class="btn btn-primary" [disabled]="isLoading"><i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                  {{isLoading ? 'Regisztráció folyamatban...' : 'Regisztráció'}}</button>
              </div>
            </div>

            <div *ngIf="!isModal" class="col-lg-12">
              <hr class="separator-hr" />
            </div>

            <div *ngIf="!isModal" class="col-lg-12 last-control-group">
              <span>Ha korábban már regisztrált:</span><a href="javascript:;" class="btn btn-link" routerLink="/login">Bejelentkezés</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

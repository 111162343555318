import { Injectable } from "@angular/core";
import { TranslateService, TranslateLoader } from "@ngx-translate/core";
import { Observable, Subject, of } from "rxjs";

@Injectable()
export class AppTranslationService {
    private onLanguageChanged = new Subject<string>();
    languageChanged$ = this.onLanguageChanged.asObservable();
    public readonly defaultLanguage = "hu";

    constructor(private translate: TranslateService) {
        this.addLanguages(["hu", "en", "de"]);
        this.setDefaultLanguage(this.defaultLanguage);
    }

    public addLanguages(lang: string[]) {
        this.translate.addLangs(lang);
    }

    public setDefaultLanguage(lang: string) {
        this.translate.setDefaultLang(lang);
    }

    public getDefaultLanguage() {
        return this.translate.defaultLang;
    }

    public getBrowserLanguage() {
        return this.translate.getBrowserLang();
    }

    public getCurrentLanguage() {
        return this.translate.currentLang;
    }

    public getLoadedLanguages() {
        return this.translate.langs;
    }

    public useBrowserLanguage(): string | void {
        const browserLang = this.getBrowserLanguage();

        if (browserLang.match(/hu|en|de/)) {
            this.changeLanguage(browserLang);
            return browserLang;
        }
    }

    public useDefaultLangage() {
        return this.changeLanguage(null);
    }

    public changeLanguage(language: string = "hu") {
        if (!language) {
            language = this.getDefaultLanguage();
        }

        if (language !== this.translate.currentLang) {
            setTimeout(() => {
                this.translate.use(language);
                this.onLanguageChanged.next(language);
            });
        }

        return language;
    }

    public getTranslation(key: string | Array<string>, interpolateParams?: Object): string | any {
        return this.translate.instant(key, interpolateParams);
    }

    public getTranslationAsync(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
        return this.translate.get(key, interpolateParams);
    }
}

export class TranslateLanguageLoader implements TranslateLoader {
    public getTranslation(lang: string): any {
        // Note Dynamic require(variable) will not work. Require is always at compile time

        switch (lang) {
            case "hu":
                return of(require("../assets/locale/hu.json"));
            case "en":
                return of(require("../assets/locale/en.json"));
            case "de":
                return of(require("../assets/locale/de.json"));
            default:
        }
    }
}

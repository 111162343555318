import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { HttpClient } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AccountEndpoint } from './account-endpoint.service';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { Group } from '../models/group.model';
import { Role } from '../models/role.model';
import { Circle } from '../models/circle.model';
import { Company } from '../models/company.model';
import { Permission, PermissionNames, PermissionValues } from '../models/permission.model';
import { UserEdit } from '../models/user-edit.model';

export type GroupsChangedOperation = 'add' | 'delete' | 'modify';
export interface GroupsChangedEventArg { groups: Group[] | string[]; operation: GroupsChangedOperation; }

@Injectable()
export class AccountService {
  public static readonly groupAddedOperation: GroupsChangedOperation = 'add';
  public static readonly groupDeletedOperation: GroupsChangedOperation = 'delete';
  public static readonly groupModifiedOperation: GroupsChangedOperation = 'modify';

  private _groupsChanged = new Subject<GroupsChangedEventArg>();

  constructor(private router: Router, private http: HttpClient, private authService: AuthService,
    private accountEndpoint: AccountEndpoint) {
  }

  public getBuildProperties() {
    return this.accountEndpoint.getBuildPropertiesEndpoint<any>();
  }

  public getUser(userId?: number) {
    return this.accountEndpoint.getUserEndpoint<User>(userId);
  }

  public getUserAndGroups(userId?: number) {
    return forkJoin(
      this.accountEndpoint.getUserEndpoint<User>(userId),
      this.accountEndpoint.getGroupsEndpoint<Group[]>());
  }

  public getUsers(page?: number, pageSize?: number) {
    return this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize);
  }

  public getUsersAndGroups(page?: number, pageSize?: number) {
    return forkJoin(
      this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize),
      this.accountEndpoint.getGroupsEndpoint<Group[]>());
  }

  public updateUser(user: UserEdit) {
    if (user.id) {
      return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
    } else {
      return this.accountEndpoint.getUserByUserNameEndpoint<User>(user.userName).pipe(
        mergeMap((foundUser) => {
          user.id = foundUser.id;
          return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
        }));
    }
  }

  public newUser(user: UserEdit) {
    return this.accountEndpoint.getNewUserEndpoint<any>(user);
  }

  public getUserPreferences() {
    return this.accountEndpoint.getUserPreferencesEndpoint<string>();
    // .map((response: Response) => response.json().data.Configuration as string);
  }

  public updateUserPreferences(configuration: string) {
    return this.accountEndpoint.getUpdateUserPreferencesEndpoint(configuration);
  }

  public deleteUser(userOrUserId: number | UserEdit): Observable<User> {
    if (typeof userOrUserId === 'number' || userOrUserId instanceof Number) {
      return this.accountEndpoint.getDeleteUserEndpoint<User>(userOrUserId as number);
    } else {
      if (userOrUserId.id) {
        return this.deleteUser(userOrUserId.id);
      } else {
        return this.accountEndpoint.getUserByUserNameEndpoint<User>(userOrUserId.userName).pipe<User>(
          mergeMap((user) => this.deleteUser(user.id)));
      }
    }
  }

  public unblockUser(userId: number) {
    return this.accountEndpoint.getUnblockUserEndpoint(userId);
  }

  public blockUser(userId: number) {
    return this.accountEndpoint.getBlockUserEndpoint(userId);
  }

  public userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some((p) => p === permissionValue);
  }

  public refreshLoggedInUser() {
    return this.authService.refreshLogin();
  }

  public getGroups(page?: number, pageSize?: number) {
    return this.accountEndpoint.getGroupsEndpoint<Group[]>(page, pageSize);
  }

  public getGroupsAndRoles(page?: number, pageSize?: number) {
    return forkJoin(
      this.accountEndpoint.getGroupsEndpoint<Group[]>(page, pageSize),
      this.accountEndpoint.getRolesEndpoint<Role[]>());
  }

  public updateGroup(group: Group) {
    if (group.id) {
      return this.accountEndpoint.getUpdateGroupEndpoint(group, group.id).pipe(
        tap((data) => this.onGroupsChanged([group], AccountService.groupModifiedOperation)));
    } else {
      return this.accountEndpoint.getGroupByGroupNameEndpoint<Role>(group.name).pipe(
        mergeMap(foundGroup => {
          group.id = foundGroup.id;
          return this.accountEndpoint.getUpdateGroupEndpoint(group, group.id);
        }),
        tap(data => this.onGroupsChanged([group], AccountService.groupModifiedOperation)));
    }
  }

  public newGroup(group: Group) {
    return this.accountEndpoint.getNewGroupEndpoint<Group>(group).pipe<Group>(
      tap((data) => this.onGroupsChanged([group], AccountService.groupAddedOperation)));
  }

  public deleteGroup(groupOrGroupId: number | Group): Observable<Group> {
    if (typeof groupOrGroupId === 'number' || groupOrGroupId instanceof Number) {
      return this.accountEndpoint.getDeleteGroupEndpoint<Group>(groupOrGroupId as number).pipe<Group>(
        tap((data) => this.onGroupsChanged([data], AccountService.groupDeletedOperation)));
    } else {
      if (groupOrGroupId.id) {
        return this.deleteGroup(groupOrGroupId.id);
      } else {
        return this.accountEndpoint.getGroupByGroupNameEndpoint<Group>(groupOrGroupId.name).pipe<Group>(
          mergeMap((group) => this.deleteGroup(group.id)));
      }
    }
  }

  public getPermissions(): Observable<Permission[]> {
    return this.accountEndpoint.getPermissionsEndpoint<Permission[]>();
  }

  public onGroupsUserCountChanged(groups: Group[] | string[]) {
    return this.onGroupsChanged(groups, AccountService.groupModifiedOperation);
  }

  public getGroupsChangedEvent(): Observable<GroupsChangedEventArg> {
    return this._groupsChanged.asObservable();
  }

  public hasUserConfirmedEmail(userId: number, code: string) {
    return this.accountEndpoint.getConfirmEmailEndpoint(userId, code);
  }

  public registrationConfirmed(userId: number) {
    return this.accountEndpoint.getRegistrationConfirmedEndpoint(userId);
  }

  public getCircles(page?: number, pageSize?: number): Observable<Circle[]> {
    return this.accountEndpoint.getCirclesEndpoint<Circle[]>(page, pageSize);
  }

  public getCompaniesAndUsers(circleId: number) {
    return forkJoin(
      this.accountEndpoint.getCompaniesByCircleIdEndpoint<Company[]>(circleId),
      this.accountEndpoint.getUsersEndpoint<User[]>());
  }

  public newCircle(circle: Circle): Observable<Circle> {
    return this.accountEndpoint.getNewCircleEndpoint<Circle>(circle);
  }

  public updateCircle(circle: Circle) {
    return this.accountEndpoint.getUpdateCircleEndpoint(circle, circle.id);
  }

  public deleteCircle(circleId: number) {
    return this.accountEndpoint.getDeleteCircleEndpoint(circleId);
  }

  private onGroupsChanged(groups: Group[] | string[], op: GroupsChangedOperation) {
    this._groupsChanged.next({ groups, operation: op });
  }

  get permissions(): PermissionValues[] {
    return this.authService.userPermissions;
  }

  get currentUser(): User {
    return this.authService.currentUser;
  }
}

import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { mergeMap, switchMap, catchError } from "rxjs/operators";

import { AuthService } from "./auth.service";
import { ConfigurationService } from "./configuration.service";


@Injectable()
export class EndpointBase {

  private taskPauser: Subject<any>;
  private isRefreshingLogin: boolean;

  private _authService: AuthService;

  private get authService() {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }

    return this._authService;
  }

  constructor(protected http: HttpClient, protected configurations: ConfigurationService, private injector: Injector) {
  }


  protected getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authService.accessToken,
      "Content-Type": "application/json",
      Accept: 'application/json, text/plain, */*'
    });

    return headers;
  }

  protected get requestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    const headers = this.getHeaders();

    return { headers };
  }

  protected handleError(error, continuation: () => Observable<any>) {
    if (error.status === 401) {
      if (this.isRefreshingLogin) {
        return this.pauseTask(continuation);
      }

      this.isRefreshingLogin = true;

      return this.authService.refreshLogin().pipe(
        mergeMap((data) => {
          this.isRefreshingLogin = false;
          this.resumeTasks(true);

          return continuation();
        }),
        catchError((refreshLoginError) => {
          this.isRefreshingLogin = false;
          this.resumeTasks(false);

          if (refreshLoginError.status === 401 || (refreshLoginError.error && refreshLoginError.error.error === 'invalid_grant')) {
            this.authService.reLogin();
            return throwError("session expired");
          } else {
            return throwError(refreshLoginError || "server error");
          }
        }));
    }

    if (error.error && error.error.error === 'invalid_grant') {
      this.authService.reLogin();
      return throwError((error.error && error.error.error_description) ? `session expired (${error.error.error_description})` : "session expired");
    } else {
      return throwError(error || "server error");
    }
  }

  private pauseTask(continuation: () => Observable<any>) {
    if (!this.taskPauser) {
      this.taskPauser = new Subject();
    }

    return this.taskPauser.pipe(switchMap((continueOp) => {
      return continueOp ? continuation() : throwError("session expired");
    }));
  }

  private resumeTasks(continueOp: boolean) {
    setTimeout(() => {
      if (this.taskPauser) {
        this.taskPauser.next(continueOp);
        this.taskPauser.complete();
        this.taskPauser = null;
      }
    });
  }
}

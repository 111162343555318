import { Injectable } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { LoginResponse, IdToken } from "../models/login-response.model";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { Observable, Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from "lodash";

import { LocalStoreManager } from "./local-store-manager.service";
// import { EndpointBase } from "../services/endpoint-base.service";
import { OidcHelperService } from './oidc-helper.service';
import { ConfigurationService } from "./configuration.service";
import { DbKeys } from "./db-keys";
import { JwtHelper } from "./jwt-helper";
import { Utilities } from "./utilities";
import { User } from "../models/user.model";
import { UserSignUp } from "../models/user-sign-up.model";
import { Permission, PermissionValues } from "../models/permission.model";

@Injectable()
export class AuthService {
  public get loginUrl() { return this.configurations.loginUrl; }
  public get homeUrl() { return this.configurations.homeUrl; }

  public loginRedirectUrl: string;
  public logoutRedirectUrl: string;

  public reLoginDelegate: () => void;

  private previousIsLoggedInCheck = false;
  private _loginStatus = new Subject<boolean>();

  constructor(private router: Router,
    private configurations: ConfigurationService,
    private oidcHelperService: OidcHelperService,
    private localStorage: LocalStoreManager,
    private appInsightsService: AppInsightsService) {
    this.initializeLoginStatus();
  }

  public gotoPage(page: string, preserveParams = true) {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: preserveParams ? "merge" : "", preserveFragment: preserveParams,
    };

    this.router.navigate([page], navigationExtras);
  }

  public redirectLoginUser() {
    const redirect = this.loginRedirectUrl && this.loginRedirectUrl !== "/" && this.loginRedirectUrl !== ConfigurationService.defaultHomeUrl ? this.loginRedirectUrl : this.homeUrl;
    this.loginRedirectUrl = null;

    const urlParamsAndFragment = Utilities.splitInTwo(redirect, "#");
    const urlAndParams = Utilities.splitInTwo(urlParamsAndFragment.firstPart, "?");

    const navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: "merge",
    };

    this.router.navigate([urlAndParams.firstPart], navigationExtras);
  }

  public redirectLogoutUser() {
    const redirect = this.logoutRedirectUrl ? this.logoutRedirectUrl : this.loginUrl;
    this.logoutRedirectUrl = null;

    this.router.navigate([redirect]);
  }

  public redirectForLogin() {
    this.loginRedirectUrl = this.router.url;
    this.router.navigate([this.loginUrl]);
  }

  public reLogin() {
    this.localStorage.deleteData(DbKeys.TOKEN_EXPIRES_IN);

    if (this.reLoginDelegate) {
      this.reLoginDelegate();
    } else {
      this.redirectForLogin();
    }
  }

  public refreshLogin() {
    return this.oidcHelperService.getRefreshLoginEndpoint().pipe(
      map((response: LoginResponse) => this.processLoginResponse(response, this.rememberMe)));
  }

  public signUp(user: UserSignUp) {
    return this.oidcHelperService.getSignUpEndpoint(user).pipe(
      map((response: Response) => this.processSignUpResponse(response)));
  }

  public login(userName: string, password: string, rememberMe?: boolean) {
    if (this.isLoggedIn) {
      this.logout();
    }

    return this.oidcHelperService.getLoginEndpoint(userName, password).pipe(
      map((response: LoginResponse) => this.processLoginResponse(response, rememberMe)));
  }

  public logout(): void {
    this.appInsightsService.clearAuthenticatedUserContext();

    this.localStorage.deleteData(DbKeys.ACCESS_TOKEN);
    this.localStorage.deleteData(DbKeys.ID_TOKEN);
    this.localStorage.deleteData(DbKeys.REFRESH_TOKEN);
    this.localStorage.deleteData(DbKeys.TOKEN_EXPIRES_IN);
    this.localStorage.deleteData(DbKeys.USER_PERMISSIONS);
    this.localStorage.deleteData(DbKeys.CURRENT_USER);

    this.configurations.clearLocalChanges();

    this.reevaluateLoginStatus();
  }

  public getLoginStatusEvent(): Observable<boolean> {
    return this._loginStatus.asObservable();
  }

  private processSignUpResponse(response: Response) {
  }

  private processLoginResponse(response: LoginResponse, rememberMe: boolean) {
    const accessToken = response.access_token;

    if (accessToken == null) {
      //throw new Error();
      throwError("Received accessToken was empty");
    }

    const idToken: string = response.id_token;
    const refreshToken: string = response.refresh_token || this.refreshToken;
    const expiresIn: number = response.expires_in;

    const tokenExpiryDate = new Date();
    tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + expiresIn);

    const accessTokenExpiry = tokenExpiryDate;

    const jwtHelper = new JwtHelper();
    const decodedIdToken = jwtHelper.decodeToken(response.id_token) as IdToken;

    let superAdministratorRole = false;
    let administratorRole = false;

    if (Array.isArray(decodedIdToken.permission)) {
      const permission = _.toArray(decodedIdToken.permission);
      if (permission.find(p => p === "SuperUser")) {
        superAdministratorRole = true;
        administratorRole = true;
        decodedIdToken.permission = Permission.superUsePermission as PermissionValues[];
      } else if (permission.find(p => p === "Administrator")) {
        administratorRole = true;
        decodedIdToken.permission = Permission.administratorPermission as PermissionValues[];
      }
    } else if (decodedIdToken.permission === "Administrator") {
      administratorRole = true;
      decodedIdToken.permission = Permission.administratorPermission as PermissionValues[];
    }

    const permissions: PermissionValues[] = Array.isArray(decodedIdToken.permission) ? decodedIdToken.permission : [decodedIdToken.permission];

    if (!this.isLoggedIn) {
      this.configurations.import(decodedIdToken.configuration);
    }

    const user = new User(
      decodedIdToken.id,
      decodedIdToken.username,
      decodedIdToken.fullname,
      decodedIdToken.email,
      decodedIdToken.jobtitle,
      decodedIdToken.phone,
      superAdministratorRole,
      administratorRole,
      Array.isArray(decodedIdToken.role) ? decodedIdToken.role : [decodedIdToken.role]);
    user.isEnabled = true;

    this.saveUserDetails(user, permissions, accessToken, idToken, refreshToken, accessTokenExpiry, rememberMe);

    this.reevaluateLoginStatus(user);
    this.appInsightsService.setAuthenticatedUserContext(user.userName);

    return user;
  }

  private saveUserDetails(user: User, permissions: PermissionValues[], accessToken: string, idToken: string, refreshToken: string, expiresIn: Date, rememberMe: boolean) {
    if (rememberMe) {
      this.localStorage.savePermanentData(accessToken, DbKeys.ACCESS_TOKEN);
      this.localStorage.savePermanentData(idToken, DbKeys.ID_TOKEN);
      this.localStorage.savePermanentData(refreshToken, DbKeys.REFRESH_TOKEN);
      this.localStorage.savePermanentData(expiresIn, DbKeys.TOKEN_EXPIRES_IN);
      this.localStorage.savePermanentData(permissions, DbKeys.USER_PERMISSIONS);
      this.localStorage.savePermanentData(user, DbKeys.CURRENT_USER);
    } else {
      this.localStorage.saveSyncedSessionData(accessToken, DbKeys.ACCESS_TOKEN);
      this.localStorage.saveSyncedSessionData(idToken, DbKeys.ID_TOKEN);
      this.localStorage.saveSyncedSessionData(refreshToken, DbKeys.REFRESH_TOKEN);
      this.localStorage.saveSyncedSessionData(expiresIn, DbKeys.TOKEN_EXPIRES_IN);
      this.localStorage.saveSyncedSessionData(permissions, DbKeys.USER_PERMISSIONS);
      this.localStorage.saveSyncedSessionData(user, DbKeys.CURRENT_USER);
    }

    this.localStorage.savePermanentData(rememberMe, DbKeys.REMEMBER_ME);
  }

  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }

  private reevaluateLoginStatus(currentUser?: User) {
    const user = currentUser || this.localStorage.getDataObject<User>(DbKeys.CURRENT_USER);
    const isLoggedIn = user != null;

    if (this.previousIsLoggedInCheck !== isLoggedIn) {
      setTimeout(() => {
        this._loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }

  get currentUser(): User {
    const user = this.localStorage.getDataObject<User>(DbKeys.CURRENT_USER);
    this.reevaluateLoginStatus(user);

    return user;
  }

  get userPermissions(): PermissionValues[] {
    return this.localStorage.getDataObject<PermissionValues[]>(DbKeys.USER_PERMISSIONS) || [];
  }

  get accessToken(): string {
    this.reevaluateLoginStatus();
    return this.localStorage.getData(DbKeys.ACCESS_TOKEN);
  }

  get accessTokenExpiryDate(): Date {
    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>(DbKeys.TOKEN_EXPIRES_IN, true);
  }

  get isSessionExpired(): boolean {
    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return !(this.accessTokenExpiryDate.valueOf() > new Date().valueOf());
  }

  get idToken(): string {
    this.reevaluateLoginStatus();
    return this.localStorage.getData(DbKeys.ID_TOKEN);
  }

  get refreshToken(): string {
    this.reevaluateLoginStatus();
    return this.localStorage.getData(DbKeys.REFRESH_TOKEN);
  }

  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  get rememberMe(): boolean {
    return this.localStorage.getDataObject<boolean>(DbKeys.REMEMBER_ME) === true;
  }
}

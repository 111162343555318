import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { AppTranslationService } from "./app-translation.service";
import { ThemeManager } from "./theme-manager";
import { LocalStoreManager } from "./local-store-manager.service";
import { DbKeys } from "./db-keys";
import { Utilities } from "./utilities";
import { environment } from "../../environments/environment";

interface UserConfiguration {
    language: string;
    homeUrl: string;
    themeId: number;
}

@Injectable()
export class ConfigurationService {

    constructor(
        private localStorage: LocalStoreManager,
        private translationService: AppTranslationService,
        private themeManager: ThemeManager) {

        this.loadLocalChanges();
    }

    set language(value: string) {
        this._language = value;
        this.saveToLocalStore(value, DbKeys.LANGUAGE);
        this.translationService.changeLanguage(value);
    }

    get language() {
        return this._language || ConfigurationService.defaultLanguage;
    }

    set themeId(value: number) {
        value = +value;
        this._themeId = value;
        this.saveToLocalStore(value, DbKeys.THEME_ID);
        this.themeManager.installTheme(this.themeManager.getThemeByID(value));
    }

    get themeId() {
        return this._themeId || ConfigurationService.defaultThemeId;
    }

    set homeUrl(value: string) {
        this._homeUrl = value;
        this.saveToLocalStore(value, DbKeys.HOME_URL);
    }

    get homeUrl() {
        return this._homeUrl || ConfigurationService.defaultHomeUrl;
    }

    // ***Specify default configurations here***
    public static readonly defaultLanguage: string = "hu";
    public static readonly defaultHomeUrl: string = "/";
    public static readonly defaultThemeId: number = 1;
    public static readonly defaultShowDashboardStatistics: boolean = true;
    public static readonly defaultShowDashboardNotifications: boolean = true;
    public static readonly defaultShowDashboardTodo: boolean = false;
    public static readonly defaultShowDashboardBanner: boolean = true;
    // ***End of defaults***

    public appVersion: string = "2.0.0";

    public baseUrl = environment.baseUrl || Utilities.baseUrl();
    public tokenUrl = environment.tokenUrl || environment.baseUrl || Utilities.baseUrl();
    public loginUrl = environment.loginUrl;
    public fallbackBaseUrl: string = Utilities.baseUrl();

    private _language: string = null;
    private _homeUrl: string = null;
    private _themeId: number = null;

    private onConfigurationImported: Subject<boolean> = new Subject<boolean>();
    configurationImported$ = this.onConfigurationImported.asObservable();

    private loadLocalChanges() {
        if (this.localStorage.exists(DbKeys.LANGUAGE)) {
            this._language = this.localStorage.getDataObject<string>(DbKeys.LANGUAGE);
            this.translationService.changeLanguage(this._language);
        } else {
            this.resetLanguage();
        }

        if (this.localStorage.exists(DbKeys.THEME_ID)) {
            this._themeId = this.localStorage.getDataObject<number>(DbKeys.THEME_ID);
            this.themeManager.installTheme(this.themeManager.getThemeByID(this._themeId));
        } else {
            this.resetTheme();
        }

        if (this.localStorage.exists(DbKeys.HOME_URL)) {
            this._homeUrl = this.localStorage.getDataObject<string>(DbKeys.HOME_URL);
        }
    }

    public import(jsonValue: string) {
        this.clearLocalChanges();

        if (jsonValue) {
            const importValue: UserConfiguration = Utilities.JsonTryParse(jsonValue);

            if (importValue.language != null) {
                this.language = importValue.language;
            }

            if (importValue.themeId != null) {
                this.themeId = +importValue.themeId;
            }

            if (importValue.homeUrl != null) {
                this.homeUrl = importValue.homeUrl;
            }
        }

        this.onConfigurationImported.next();
    }

    public export(changesOnly = true): string {
        const exportValue: UserConfiguration = {
            language: changesOnly ? this._language : this.language,
            themeId: changesOnly ? this._themeId : this.themeId,
            homeUrl: changesOnly ? this._homeUrl : this.homeUrl
        };

        return JSON.stringify(exportValue);
    }

    public clearLocalChanges() {
        this._language = null;
        this._homeUrl = null;
        this._themeId = null;

        this.localStorage.deleteData(DbKeys.LANGUAGE);
        this.localStorage.deleteData(DbKeys.HOME_URL);
        this.localStorage.deleteData(DbKeys.THEME_ID);

        this.resetLanguage();
        this.resetTheme();
    }

    private resetLanguage() {
        const language = this.translationService.useBrowserLanguage();

        if (language) {
            this._language = language;
        } else {
            this._language = this.translationService.useDefaultLangage();
        }
    }

    private resetTheme() {
        this.themeManager.installTheme();
        this._themeId = null;
    }

    private saveToLocalStore(data: any, key: string) {
        setTimeout(() => this.localStorage.savePermanentData(data, key));
    }
}

import { Component, OnInit, OnDestroy  } from "@angular/core";
import { fadeInOut } from "../../services/animations";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, MessageSeverity } from "src/app/services/alert.service";
import { AccountService } from "src/app/services/account.service";
import { AppTranslationService } from "src/app/services/app-translation.service";

@Component({
    animations: [fadeInOut],
    styleUrls: ["./account-confirm-registratrion.component.scss"],
    templateUrl: "./account-confirm-registratrion.component.html",
})

export class AccountConfirmRegistratrionComponent implements OnInit, OnDestroy {
    private userId: number;
    private sub: any;

    constructor(private route: ActivatedRoute,
      private router: Router,
      private translationService: AppTranslationService,
      private alertService: AlertService,
      private accountService: AccountService) { }

      public ngOnInit() {
        this.sub = this.route.params.subscribe((params) => {
          this.userId = +params.userId;
          this.accountService.registrationConfirmed(this.userId)
            .subscribe(() => this.onDataLoadSuccessful(), (error) => this.onDataLoadFailed(error));
        });
      }

      public onDataLoadSuccessful() {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage(this.translation("serviceMessage.Success"), "Sikeres felhasználó aktíválás!", MessageSeverity.success);
      }

      public onDataLoadFailed(error?: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Hiba az elfogadáskor!", "Nem sikerült a felhasználó aktíválása!", MessageSeverity.error, error);
      }

      public ngOnDestroy() {
        this.sub.unsubscribe();
      }

      public goToLoginPage() {
        this.router.navigate(["/"]);
      }

      private translation(key: string): any {
        return this.translationService.getTranslation(key);
      }
}

export class NavOnline {
  public id: number;
  public cegId: number;
  public navUserName: string;
  public taxNumber: string;
  public xmlSignKey: string;
  public exchangeKey: string;
  public password: string;
  public immediateEmailNotification: boolean;
  public dailyEmailNotification: boolean;
  public notificationEmailAddress: string;
  public dailyNotificationEmailAddress: string;
}

export class Company {
    constructor(id?: number, kod?: string,
                nev?: string, rovidNev?: string,
                adoSzamHU?: string, adoSzamEU?: string,
                adoAzon?: string, orszagKod?: string,
                iranyitoSzam?: string, telepules?: string,
                kozteruletNeve?: string, kozteruletJellege?: string,
                hazszam?: string, kodAktEv?: string,
                circleName?: string
    ) {
        this.id = id;
        this.kod = kod;
        this.nev = nev;
        this.rovidNev = rovidNev;
        this.adoSzamHU = adoSzamHU;
        this.adoSzamEU = adoSzamEU;
        this.adoAzon = adoAzon;
        this.orszagKod = orszagKod;
        this.iranyitoSzam = iranyitoSzam;
        this.telepules = telepules;
        this.kozteruletNeve = kozteruletNeve;
        this.kozteruletJellege = kozteruletJellege;
        this.hazszam = hazszam;
        this.circleName = circleName;
        this.kodAktEv = kodAktEv;
    }

    public id: number;
    public kod: string;
    public nev: string;
    public rovidNev: string;
    public adoSzamHU: string;
    public adoSzamEU: string;
    public adoAzon: string;
    public orszagKod: string;
    public iranyitoSzam: string;
    public telepules: string;
    public kozteruletNeve: string;
    public kozteruletJellege: string;
    public hazszam: string;
    public kodAktEv: string;
    public circleName: string;
}
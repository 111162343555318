import { Component } from "@angular/core";
import { fadeInOut } from "../../services/animations";

@Component({
    animations: [fadeInOut],
    selector: "legal",
    styleUrls: ["./legal.component.scss"],
    templateUrl: "./legal.component.html",
})

export class LegalComponent {
}
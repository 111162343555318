<div class="app-component app-container">
  <ngx-toasta></ngx-toasta>
  <nav id="header" class="app-component navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
    <div class="container">
      <a ngPreserveWhitespaces class="app-component navbar-brand" routerLink="/">
        <img src="assets/images/logo.png" class="d-inline-block align-top" alt="logo">
        <span class="app-component appTitle">{{appTitle}}</span>
      </a>
      <button type="button" class="app-component navbar-toggler" data-toggle="collapse" data-target=".menuItemsContainer.app-component">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="hidden-xs visible-xl app-component collapse navbar-collapse menuItemsContainer">
        <ul class="app-component nav nav-pills flex-column flex-lg-row mr-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              {{'mainMenu.System' | translate}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/features">Keretrendszer</a>
              <a class="dropdown-item" routerLink="/features/accounting-features">Kettős könyvvitel</a>
              <a class="dropdown-item" routerLink="/features/einvoice-features">NAV online számla</a>
              <div class="dropdown-divider"></div>
              <a *ngIf="isUserLoggedIn" class="dropdown-item" href="https://novaplansoft.blob.core.windows.net/production-drop-location/Setup.exe">KUR
                telepítése</a>
            </div>
          </li>
        </ul>
        <!-- <div class="hidden-xs visible-xl btn-group" dropdown>
          <button dropdownToggle type="button" class="btn btn-primary-outline dropdown-toggle navbar-text">
            {{'mainMenu.System' | translate}} <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="nav-item" routerLinkActive="active"><a class="dropdown-item" routerLink="/features">Keretrendszer</a></li>
            <li role="nav-item" routerLinkActive="active"><a class="dropdown-item" routerLink="/features/accounting-features">Kettős könyvvitel</a>
            </li>
            <li role="nav-item" routerLinkActive="active"><a class="dropdown-item" routerLink="/features/einvoice-features">NAV online számla</a></li>
            <li *ngIf="isUserLoggedIn">
              <a class="dropdown-item" href="https://novaplansoft.blob.core.windows.net/production-drop-location/Setup.exe">KUR telepítése</a>
            </li>
          </ul>
        </div> -->
      </div>
      <div *ngIf="isUserLoggedIn" class="app-component collapse navbar-collapse menuItemsContainer">
        <ul class="app-component nav nav-pills flex-column flex-lg-row ml-auto">
          <li class="nav-item" routerLinkActive="active" *ngIf="canViewAdministratorMenu">
            <a class="nav-link" routerLink="/admin">{{'mainMenu.Admin' | translate}}</a>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngIf="canViewCompanies && !canViewAdministratorMenu">
            <a class="nav-link" routerLink="/companies">{{'mainMenu.Companies' | translate}}</a>
          </li>
        </ul>
        <ul class="app-component nav nav-pills flex-column flex-lg-row mr-md-2">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/settings"><i class="fa fa-cog"></i></a>
          </li>
        </ul>
        <span class="app-component navbar-text notifications-popup">{{'app.Welcome' | translate}}</span>
        <a class="app-component nav-link user-name d-inline-block px-1" (onHidden)="markNotificationsAsRead()" placement="bottom" href="javascript:;"
          triggers="focus">
          {{fullName}}
          <span *ngIf="newNotificationCount > 0" class="badge badge-pill badge-secondary">{{newNotificationCount}}</span>
        </a>
        <!-- <ng-template #popTemplate>
          <app-notifications-viewer [isViewOnly]="true"></app-notifications-viewer>
        </ng-template> -->
        <ul class="app-component nav nav-pills ml-lg-2 flex-column flex-lg-row">
          <li class="nav-item" (click)="logout()">
            <a class="nav-link" href="javascript:;"><i class="fa fa-sign-out"></i> {{'mainMenu.Logout' | translate}}</a>
          </li>
        </ul>
      </div>
      <div *ngIf="!isUserLoggedIn && router.isActive('/about', false)" class="app-component collapse navbar-collapse menuItemsContainer">
        <ul class="app-component nav nav-pills flex-column flex-lg-row ml-auto">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/login"><i class="fa fa-sign-in"></i></a>
          </li>
        </ul>
      </div>
      <div *ngIf="!isUserLoggedIn && !router.isActive('/about', false) || false" class="app-component collapse navbar-collapse menuItemsContainer">
        <ul class="app-component nav nav-pills flex-column flex-lg-row ml-auto">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/about"><i class="fa fa-info-circle"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="pre-bootstrap" *ngIf="!removePrebootScreen" [class.prebootShow.app-component]="!isAppLoaded" class="app-component prebootStep">
    <div class="messaging">
      <h1>
        Betöltve!
      </h1>
      <p>
        KUR - <span class="appName" style="font-style:italic">Komplex Ügyviteli Rendszer</span><span class="appName"></span> &copy; <a
          href="">{{getHostUrl()}}</a>
      </p>
    </div>
  </div>

  <main class="app-component container">
    <router-outlet></router-outlet>
    <div class="app-component footer-height"></div>
  </main>

  <footer class="app-component footer fixed-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <a routerLink="/legal">ÁSZF és adatvédelem</a>
        </div>
        <div class="col-md-6">
          <span class="appName" style="font-style:italic">KUR</span><span class="appName"> - Komplex Ügyviteli Rendszer</span> &copy; {{getYear()}}
          <a href="" target="_blank">{{getHostUrl()}}</a>
        </div>
        <div class="col-md-2">
          <!--<a *ngIf="isAutomaticallyBuilt" href="{{buildUrl}}" target="_blank">{{buildLabel}}</a>-->
          <span *ngIf="isAutomaticallyBuilt">{{buildLabel}}</span>
          <span *ngIf="!isAutomaticallyBuilt">Developer Build</span>
        </div>
      </div>
    </div>
    <cookie-law>Az <a routerLink="/legal">ÁSZF és adatvédelem</a> alatt megtalálja, hogyan gondoskodunk adatai védelméről. <span>Oldalainkon
        HTTP-sütiket használunk a jobb működésért.</span></cookie-law>
  </footer>

  <div *ngIf="shouldShowLoginModal" class="modal fade login-control" bsModal #loginModal="bs-modal" (onShown)="onLoginModalShown()"
    (onHidden)="onLoginModalHidden()" (onHide)="onLoginModalHide()" [config]="{backdrop: 'static'}" tabindex="-1">
    <div class="modal-dialog modal-lg h-75 d-flex flex-column justify-content-center my-0">
      <div class="modal-content">
        <div class="modal-body">
          <app-login #loginControl isModal="true"></app-login>
        </div>
      </div>
    </div>
  </div>
</div>

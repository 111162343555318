import { Component, OnInit, OnDestroy } from "@angular/core";
import { fadeInOut } from "../../services/animations";
import { ActivatedRoute, Router } from "@angular/router";

import { AlertService, MessageSeverity } from "../../services/alert.service";
import { AccountService } from "../../services/account.service";

@Component({
    animations: [fadeInOut],
    styleUrls: ["./account-confirm-email.component.scss"],
    templateUrl: "./account-confirm-email.component.html",
})

export class AccountConfirmEmailComponent implements OnInit, OnDestroy {
    public isSuccessfulEmailConfirmation: boolean;
    private userId: number;
    private code: string;
    private sub: any;

    constructor(private route: ActivatedRoute,
      private router: Router,
      private alertService: AlertService,
      private accountService: AccountService) { }

    public ngOnInit() {
        this.sub = this.route.params.subscribe((params) => {
            this.userId = +params.userId;
            this.code = params.code;
            this.accountService.hasUserConfirmedEmail(this.userId, this.code)
                .subscribe(() => this.onDataLoadSuccessful(), (error) => this.onDataLoadFailed(error));
        });
    }

    public ngOnDestroy() {
        this.sub.unsubscribe();
    }

    public onDataLoadSuccessful() {
        this.alertService.stopLoadingMessage();
        this.isSuccessfulEmailConfirmation = true;
    }

    public onDataLoadFailed(error?: any) {
        this.alertService.stopLoadingMessage();

        this.alertService.showStickyMessage("Hiba az elfogadáskor!", "Nem sikerült az email megerősítése.", MessageSeverity.error, error);
    }

    public goToLoginPage() {
        this.router.navigate(["/login"]);
    }
}

<div class="container">
    <header class="pageHeader">
        <h3><i class="fa fa-tachometer fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.Dashboard' | translate}}</h3>
    </header>

    <div [@fadeInOut]>
        <div class="well well-lg">
            <h4 class="text-muted text-center">
                {{'home.NoWidgets1' | translate}} <a routerLink="/settings" fragment="preferences"><i class="fa fa-sliders"></i> {{'settings.tab.Preferences' | translate}}</a>
                {{'home.NoWidgets2' | translate}}
            </h4>
        </div>
    </div>
</div>
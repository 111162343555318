import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { AuthService } from "../../services/auth.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { Utilities } from "../../services/utilities";
import { Group } from "../../models/group.model";
import { Role } from "../../models/role.model";
import { Permission } from "../../models/permission.model";
import { GroupEditorComponent } from "./group-editor.component";

@Component({
  selector: "group-management",
  templateUrl: "./group-management.component.html",
  styleUrls: ["./group-management.component.scss"]
})
export class GroupManagementComponent implements OnInit, AfterViewInit {
  public columns: any[] = [];
  public rows: Group[] = [];
  public rowsCache: Group[] = [];
  public allRoles: Role[] = [];
  public editedGroup: Group;
  public sourceGroup: Group;
  public editingGroupName: { name: string };
  public loadingIndicator: boolean;

  @ViewChild("indexTemplate", { static: true })
  public indexTemplate: TemplateRef<any>;

  @ViewChild("actionsTemplate", { static: true })
  public actionsTemplate: TemplateRef<any>;

  @ViewChild("editorModal", { static: true })
  public editorModal: ModalDirective;

  @ViewChild("groupEditor", { static: true })
  public groupEditor: GroupEditorComponent;

  @ViewChild("userForSuperAdministratorTemplate", { static: true })
  public userForSuperAdministratorTemplate: TemplateRef<any>;

  constructor(private alertService: AlertService,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private accountService: AccountService) {
  }

  public ngOnInit() {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      { prop: "name", name: gT("groups.management.Name"), width: 120 },
      { prop: "description", name: gT("groups.management.Description"), width: 140 }
    ];

    if (this.canViewCircles) {
      this.columns.push({ prop: "circleName", name: gT("groups.management.CircleName"), width: 120, cellTemplate: this.userForSuperAdministratorTemplate });
    }

    if (this.actionsTemplate) {
      this.columns.push({ name: "", width: 190, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    }

    this.loadData();
  }

  public ngAfterViewInit() {
    this.groupEditor.changesSavedCallback = () => {
      this.addNewGroupToList();
      this.editorModal.hide();
    };

    this.groupEditor.changesCancelledCallback = () => {
      this.editedGroup = null;
      this.sourceGroup = null;
      this.editorModal.hide();
    };
  }

  public addNewGroupToList() {
    if (this.sourceGroup) {
      Object.assign(this.sourceGroup, this.editedGroup);

      let sourceIndex = this.rowsCache.indexOf(this.sourceGroup, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);
      }

      sourceIndex = this.rows.indexOf(this.sourceGroup, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);
      }

      this.editedGroup = null;
      this.sourceGroup = null;
    } else {
      const group = new Group();
      Object.assign(group, this.editedGroup);
      this.editedGroup = null;

      let maxIndex = 0;
      for (const r of this.rowsCache) {
        if ((r as any).index > maxIndex) {
          maxIndex = (r as any).index;
        }
      }

      (group as any).index = maxIndex + 1;

      this.rowsCache.push(group);
      this.rows.push(group);
      this.rows = [...this.rows];
    }
  }

  public loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.accountService.getGroupsAndRoles()
      .subscribe((results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        const groups = results[0];
        const roles = results[1];

        groups.forEach((group, index, groups) => {
          (group as any).index = index + 1;
        });

        this.rowsCache = [...groups];
        this.rows = groups;

        this.allRoles = roles;
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.LoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  public onSearchChanged(value: string) {
    if (this.canViewCircles) {
      this.rows = this.rowsCache.filter((r) => Utilities.searchArray(value, false, r.name, r.description, r.circleName));
    } else {
      this.rows = this.rowsCache.filter((r) => Utilities.searchArray(value, false, r.name, r.description));
    }
  }

  public onEditorModalHidden() {
    this.editingGroupName = null;
    this.groupEditor.resetForm(true);
  }

  public newGroup() {
    this.editingGroupName = null;
    this.sourceGroup = null;
    this.editedGroup = this.groupEditor.newGroup(this.allRoles);
    this.editorModal.show();
  }

  public editGroup(row: Group) {
    this.editingGroupName = { name: row.name };
    this.sourceGroup = row;
    this.editedGroup = this.groupEditor.editGroup(row, this.allRoles);
    this.editorModal.show();
  }

  public deleteGroup(row: Group) {
    this.alertService.showDialog(`${this.translation("serviceMessage.ConfirmDelete")} ${row.name}`, DialogType.confirm, () => this.deleteGroupHelper(row));
  }

  public deleteGroupHelper(row: Group) {
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Deleting"));
    this.loadingIndicator = true;

    this.accountService.deleteGroup(row)
      .subscribe((results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter((item) => item !== row);
        this.rows = this.rows.filter((item) => item !== row);
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.DeleteError")}"${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }

  get canManageGroups() {
    return this.accountService.userHasPermission(Permission.manageGroupsPermission);
  }

  get canViewCircles(): boolean {
    if (this.authService.currentUser) {
      return this.authService.currentUser.isSuperAdministrator;
    }
    return false;
  }
}

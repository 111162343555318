<div class="d-flex h-90">
  <div class="login-container m-auto">
    <div class="card boxshadow">
      <div class="card-header bg-primary dark text-white clearfix">
        <i class="fa fa-lock" aria-hidden="true"></i> Bejelentkezés
        <button *ngIf="isModal" type="button" class="close float-right text-light" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="card-body">
        <div class="col-md-8 offset-md-2">
          <form *ngIf="formResetToggle" class="form-horizontal login" name="loginForm" #f="ngForm" novalidate (ngSubmit)="f.form.valid ? login() :
                (!userName.valid && showErrorAlert('Felhasználó név megadása kötelező', 'Kérjük, hogy adja meg az adatot!'));
                (!password.valid && showErrorAlert('Jelszó megadása kötelező', 'Kérjük, hogy adja meg az adatot!'))">
            <div class="form-group row has-feedback-icon">
              <label class="col-form-label col-md-6" for="login-userName">Felhasználó név:</label>
              <div class="col-md-8" [ngClass]="{'is-valid': f.submitted && userName.valid, 'is-invalid' : f.submitted && !userName.valid}">
                <input type="text" id="login-userName" name="userName" placeholder="Felhasználó név bevitele" class="form-control"
                  [(ngModel)]="userLogin.userName" #userName="ngModel" required minlength="3" />
                <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': userName.valid, 'fa-times' : !userName.valid}"
                  aria-hidden="true"></span>
                <span class="invalid-feedback" *ngIf="f.submitted && userName.errors && userName.errors.required">
                  Felhasználó név megadása kötelező.
                </span>
                <span class="invalid-feedback" *ngIf="f.submitted && userName.errors && userName.errors.minlength">
                  Minimum 3 karakter megadása szükséges!
                </span>
              </div>
            </div>
            <div class="form-group row has-feedback-icon">
              <label class="col-form-label col-md-6" for="login-password">Jelszó:</label>
              <div class="col-md-8" [ngClass]="{'is-valid': f.submitted && password.valid, 'is-invalid' : f.submitted && !password.valid}">
                <input type="password" id="login-password" name="password" placeholder="Jelszó bevitele" class="form-control"
                  [(ngModel)]="userLogin.password" #password="ngModel" required minlength="6" />
                <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': password.valid, 'fa-times' : !password.valid}"
                  aria-hidden="true"></span>
                <span class="invalid-feedback" *ngIf="f.submitted && password.errors && password.errors.required">
                  Jelszó megadása kötelező.
                </span>
                <span class="invalid-feedback" *ngIf="f.submitted && password.errors && password.errors.minlength">
                  Minimum 6 karakter megadása szükséges!
                </span>
              </div>
            </div>
            <div class="form-group">
              <div class="offset-md-4 col-md-8">
                <div class="checkbox">
                  <label><input type="checkbox" name="rememberMe" [(ngModel)]="userLogin.rememberMe"> Emlékezz rám</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="offset-md-4 col-md-8">
                <button type="submit" class="btn btn-primary" [disabled]="isLoading"><i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                  {{isLoading ? 'Bejelentkezés folyamatban...' : 'Bejelentkezés'}}</button>
              </div>
            </div>

            <div *ngIf="!isModal" class="col-lg-12">
              <hr class="separator-hr" />
            </div>

            <div *ngIf="!isModal" class="col-lg-12 last-control-group">
              <span>Nem regisztrált még rendszerünkbe?</span><a href="javascript:;" class="btn btn-link" routerLink="/signup">Regisztráció</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

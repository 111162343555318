import { registerLocaleData } from "@angular/common";
import localeHu from "@angular/common/locales/hu";
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from "ngx-toasta";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { AppInsightsService } from "@markpieszak/ng-application-insights";

import { LoginComponent } from "../components/login/login.component";
import { SignUpComponent } from "../components/sign-up/sign-up.component";
import { Permission } from "../models/permission.model";
import { AccountService } from "../services/account.service";
import { AlertDialog, AlertMessage, AlertService, DialogType, MessageSeverity } from "../services/alert.service";
import { AppTitleService } from "../services/app-title.service";
import { AppTranslationService } from "../services/app-translation.service";
import { AuthService } from "../services/auth.service";
import { ConfigurationService } from "../services/configuration.service";
import { LocalStoreManager } from "../services/local-store-manager.service";
import { NotificationService } from "../services/notification.service";

import { Utilities } from "./../services/utilities";

const alertify: any = require("../assets/scripts/alertify.js");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public isAppLoaded: boolean;
  public isUserLoggedIn: boolean;
  public shouldShowLoginModal: boolean;
  public removePrebootScreen: boolean;
  public newNotificationCount = 0;
  public appTitle = "- Komplex Ügyviteli Rendszer";

  public stickyToasties: number[] = [];

  public dataLoadingConsecutiveFailures = 0;
  public notificationsLoadingSubscription: any;

  @ViewChildren("loginModal,loginControl")
  public modalLoginControls: QueryList<any>;

  public loginModal: ModalDirective;
  public loginControl: LoginComponent;

  public isAutomaticallyBuilt: boolean = false;
  public buildLabel: string;

  get notificationsTitle() {
    const gT = (key: string) => this.translationService.getTranslation(key);

    if (this.newNotificationCount) {
      return `${gT("app.Notifications")} (${this.newNotificationCount} ${gT("app.New")})`;
    } else {
      return gT("app.Notifications");
    }
  }

  constructor(
    storageManager: LocalStoreManager,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private accountService: AccountService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private appTitleService: AppTitleService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    public configurations: ConfigurationService,
    public router: Router,
    private appInsightsService: AppInsightsService) {
    storageManager.initialiseStorageSyncListener();

    translationService.addLanguages(["hu", "en", "de"]);
    translationService.setDefaultLanguage("hu");
    registerLocaleData(localeHu, "Hu");

    this.toastaConfig.theme = "bootstrap";
    this.toastaConfig.position = "top-right";
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;

    this.appTitleService.appName = this.appTitle;
  }

  public ngAfterViewInit() {
    this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach((control) => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.modalClosedCallback = () => this.loginModal.hide();
          } else {
            this.loginModal = control;
            this.loginModal.show();
          }
        }
      });
    });
  }

  public onLoginModalShown() {
    this.alertService.showStickyMessage(this.translation("serviceMessage.SessionExpired"), this.translation("serviceMessage.SessionExpiredMessage"), MessageSeverity.info);
  }

  public onLoginModalHidden() {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = false;

    if (this.authService.isSessionExpired) {
      this.alertService.showStickyMessage(this.translation("serviceMessage.SessionExpired"), this.translation("serviceMessage.SessionExpiredMessage"), MessageSeverity.warn);
    }
  }

  public onLoginModalHide() {
    this.alertService.resetStickyMessage();
  }

  public ngOnInit() {

    this.accountService.getBuildProperties().subscribe((result) => {
      this.isAutomaticallyBuilt = result.isAutomaticallyBuilt;
      this.buildLabel = result.buildLabel;
      this.configurations.appVersion = result.buildLabel;
    });

    this.isUserLoggedIn = this.authService.isLoggedIn;

    // 0.5 extra sec to display preboot/loader information. Preboot screen is removed 0.5 sec later
    setTimeout(() => this.isAppLoaded = true, 500);
    setTimeout(() => this.removePrebootScreen = true, 1000);

    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();

        // if (!this.authService.isSessionExpired)
        this.alertService.showMessage(this.translation("serviceMessage.Login"), `Üdvözöljük ${this.fullName}!`, MessageSeverity.default);
        // else
        //    this.alertService.showStickyMessage("Session Expired", "Your Session has expired. Please log in again", MessageSeverity.warn);
      }
    }, 2000);

    this.alertService.getDialogEvent().subscribe((alert) => this.showDialog(alert));
    this.alertService.getMessageEvent().subscribe((message) => this.showToast(message, false));
    this.alertService.getStickyMessageEvent().subscribe((message) => this.showToast(message, true));

    this.authService.reLoginDelegate = () => this.shouldShowLoginModal = true;

    this.authService.getLoginStatusEvent().subscribe((isLoggedIn) => {
      this.isUserLoggedIn = isLoggedIn;

      if (this.isUserLoggedIn) {
        this.initNotificationsLoading();
      } else {
        this.unsubscribeNotifications();
      }

      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          this.alertService.showMessage(this.translation("serviceMessage.SessionExpired"), "", MessageSeverity.default);
        }
      }, 500);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const navigation = (event as NavigationStart);
        this.appInsightsService.trackPageView(navigation.url);
        this.appInsightsService.flush();
        //    if (url !== url.toLowerCase()) {
        //        this.router.navigateByUrl((event as NavigationStart).url.toLowerCase());
        //    }
      }
    });
  }

  public ngOnDestroy() {
    this.unsubscribeNotifications();
  }

  public initNotificationsLoading() {
    this.notificationsLoadingSubscription = this.notificationService.getNewNotificationsPeriodically()
      .subscribe((notifications) => {
        this.dataLoadingConsecutiveFailures = 0;
        this.newNotificationCount = notifications.filter((n) => !n.isRead).length;
      },
        (error) => {
          this.alertService.logError(error);

          if (this.dataLoadingConsecutiveFailures++ < 20) {
            setTimeout(() => this.initNotificationsLoading(), 5000);
          } else {
            this.alertService.showStickyMessage(this.translation("serviceMessage.LoadError"), this.translation("serviceMessage.LoadNotificationError"), MessageSeverity.error);
          }
        });
  }

  public markNotificationsAsRead() {
    const recentNotifications = this.notificationService.recentNotifications;

    if (recentNotifications.length) {
      this.notificationService.readUnreadNotification(recentNotifications.map((n) => n.id), true)
        .subscribe((response) => {
          for (const n of recentNotifications) {
            n.isRead = true;
          }

          this.newNotificationCount = recentNotifications.filter((n) => !n.isRead).length;
        },
          (error) => {
            this.alertService.logError(error);
            this.alertService.showMessage(this.translation("serviceMessage.NotificationError"), this.translation("serviceMessage.NotificationReadErrorMessage"), MessageSeverity.error);
          });
    }
  }

  public showDialog(dialog: AlertDialog) {
    alertify.set({
      labels: {
        ok: dialog.okLabel || "Igen",
        cancel: dialog.cancelLabel || "Mégse",
      },
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break;
      case DialogType.confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          });

        break;
      case DialogType.prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          }, dialog.defaultValue);

        break;
    }
  }

  public showToast(message: AlertMessage, isSticky: boolean) {
    if (message == null) {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    const toastOptions: ToastOptions = {
      title: message.summary,
      msg: message.detail,
      timeout: isSticky ? 0 : 4000,
    };

    if (isSticky) {
      toastOptions.onAdd = (toast: ToastData) => this.stickyToasties.push(toast.id);

      toastOptions.onRemove = (toast: ToastData) => {
        const index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    }

    switch (message.severity) {
      case MessageSeverity.default: this.toastaService.default(toastOptions); break;
      case MessageSeverity.info: this.toastaService.info(toastOptions); break;
      case MessageSeverity.success: this.toastaService.success(toastOptions); break;
      case MessageSeverity.error: this.toastaService.error(toastOptions); break;
      case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
    }
  }

  public getHostUrl() {
    return this.configurations.baseUrl.replace("http://", "").replace("https://", "");
  }

  public logout() {
    this.authService.logout();
    this.authService.redirectLogoutUser();
  }

  public getYear() {
    return new Date().getUTCFullYear();
  }

  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : "";
  }

  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : "";
  }

  get canViewCompanies() {
    return Utilities.userHasRole(this.accountService.currentUser, "CegRead");
  }

  get canViewAdministratorMenu() {
    return this.authService.currentUser ? this.authService.currentUser.isAdministrator : false;
  }

  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription) {
      this.notificationsLoadingSubscription.unsubscribe();
    }
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }
}

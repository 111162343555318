import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AngularDualListBoxModule } from "angular-dual-listbox";


import { CustomDualListComponent } from "./custom-dual-list.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
    ],
    declarations: [
        CustomDualListComponent,
    ],
    exports: [
        CustomDualListComponent,
    ],
})
export class CustomDualListModule { }
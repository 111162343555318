import { Component } from "@angular/core";
import { fadeInOut } from "../../../services/animations";

@Component({
    selector: "einvoice-features",
    templateUrl: "./einvoice-features.component.html",
    styleUrls: ["./einvoice-features.component.scss"],
    animations: [fadeInOut],
})
export class EinvoiceFeaturesComponent {
}
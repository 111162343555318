<div class="container">
    <header class="pageHeader">
        <h3>
            <i class="fa fa-exclamation-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.Contact' | translate}}
        </h3>
    </header>

    <div [@fadeInOut]>
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <p> Novaplan Szoftver Kft </p>
                <p> 571 Gyula, Szarkaláb utca 17. </p>
                <p> 1214 Budapest, Kossuth Lajos utca 112. </p>
                <p>
                    Az <a href="mailto:info@novaplan-soft.com?Subject=Kur Project Template.">info@novaplan-soft.com </a> címen elér minket.
                </p>
            </div>
        </div>
    </div>
</div>
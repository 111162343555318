import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";

import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { ConfigurationService } from "../../services/configuration.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { BootstrapSelectDirective } from "../../directives/bootstrap-select.directive";
import { AccountService } from "../../services/account.service";
import { ThemeManager } from "../../services/theme-manager";
import { Utilities } from "../../services/utilities";
import { Permission } from "../../models/permission.model";

@Component({
  selector: "user-preferences",
  templateUrl: "./user-preferences.component.html",
  styleUrls: ["./user-preferences.component.scss"]
})
export class UserPreferencesComponent implements OnInit, OnDestroy {
  public themeSelectorToggle = true;

  public languageChangedSubscription: any;

  @ViewChild("languageSelector", { static: true })
  public languageSelector: BootstrapSelectDirective;

  @ViewChild("homePageSelector", { static: true })
  public homePageSelector: BootstrapSelectDirective;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    public themeManager: ThemeManager,
    public configurations: ConfigurationService) {
  }

  public ngOnInit() {
    this.languageChangedSubscription = this.translationService.languageChanged$.subscribe(data => {
      this.themeSelectorToggle = false;

      setTimeout(() => {
        this.languageSelector.refresh();
        this.homePageSelector.refresh();
        this.themeSelectorToggle = true;
      });
    });
  }

  public ngOnDestroy() {
    this.languageChangedSubscription.unsubscribe();
  }

  public reloadFromServer() {
    this.alertService.startLoadingMessage();

    this.accountService.getUserPreferences()
      .subscribe((results) => {
        this.alertService.stopLoadingMessage();

        this.configurations.import(results);

        this.alertService.showMessage("Alapértelmezett beállítások betöltve!", "", MessageSeverity.info);
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage("Hiba történt.", `Nem sikerült az adatok letöltése.\r\nHiba üzenet: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  public setAsDefault() {
    this.alertService.showDialog("Biztosan alapértelmezetté teszi a kiválasztott beállításokat?", DialogType.confirm,
      () => this.setAsDefaultHelper(),
      () => this.alertService.showMessage("Megszakítva.", "", MessageSeverity.default));
  }

  public setAsDefaultHelper() {
    this.alertService.startLoadingMessage("", "Saving new defaults");

    this.accountService.updateUserPreferences(this.configurations.export())
      .subscribe((response) => {
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("New Defaults", "Account defaults updated successfully", MessageSeverity.success);
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage("Save Error", `An error occured whilst saving configuration defaults.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  public resetDefault() {
    this.alertService.showDialog("Are you sure you want to reset your defaults?", DialogType.confirm,
      () => this.resetDefaultHelper(),
      () => this.alertService.showMessage("Operation Cancelled!", "", MessageSeverity.default));
  }

  public resetDefaultHelper() {
    this.alertService.startLoadingMessage("", "Resetting defaults");

    this.accountService.updateUserPreferences(null)
      .subscribe((response) => {
        this.alertService.stopLoadingMessage();
        this.configurations.import(null);
        this.alertService.showMessage("Defaults Reset", "Account defaults reset completed successfully", MessageSeverity.success);
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage("Save Error", `An error occured whilst resetting configuration defaults.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  get canViewCustomers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); // eg. viewCustomersPermission
  }

  get canViewProducts() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); // eg. viewProductsPermission
  }

  get canViewOrders() {
    return true; // eg. viewOrdersPermission
  }
}
